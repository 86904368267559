<div class="content-body">
  <div class="row-container">
    <div class="text-col">
      <h2>Impressum</h2>
      <br />
      <p>
        <strong>Adresse:</strong><br />GMG Generalmietgesellschaft mbH&nbsp;<br />Sternengasse
        14-16&nbsp;<br />50676 Köln&nbsp;<br />
        Amtsgericht Köln HRB 81574 <br />
        Sitz der Gesellschaft Köln <br />
        USt-IdNr. DE 151269110<br />E-Mail: real_estate&#64;telekom.de&nbsp;<br />Telefon:
        0228 / 181-0&nbsp;
      </p>
      <br />
      <p>
        <strong>Kontakt:</strong><br />Bei Fragen zu unseren Immobilien nutzen
        Sie bitte unsere
        <a href="/landing/contact" target="_blank" title="Kontaktseite"
          ><span>Kontaktseite</span></a
        >.
      </p>
      <br />
      <p>
        <strong>Geschäftsführung:</strong><br />Rainer Hoff<br />Dagmar Ecken<br />Michael
        Schlombs<br />
      </p>
      <br />
      <p>
        <strong>Verantwortlich:</strong><br />Deutsche Telekom AG<br />Group
        Supply Services - Real Estate Management<br />Michael Schildger<br />Strategie,
        Prozesse und Qualität<br />Sternengasse 14-16 <br />50676 Köln
      </p>
    </div>
    <div class="image-container">
      <img src="../../../assets/images/undraw_impressum.svg" />
    </div>
  </div>
</div>
