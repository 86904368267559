import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-telekom-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public mainNavContent = [
    {
      name: 'Home',
      id: '/landing/home',
      childroutes: ['/home', '/who', '/contact'],
      onClick: () => this.routeTo('/landing/home'),
    },
    {
      name: 'Über uns',
      id: '/landing/who',
      onClick: () => this.routeTo('/landing/who'),
    },
    {
      name: 'Kontakt',
      id: '/landing/contact',
      onClick: () => this.routeTo('/landing/contact'),
    },
    // {
    //   name: 'Login',
    //   id: 'Login',
    //   onClick: () => this.OnB2CLoginClick(),
    // },
    // {
    //   name: 'Aktuelles',
    //   id: '/landing/news',
    //   childroutes: ['/landing/podcast'],
    //   onClick: () => this.routeTo('/landing/news'),
    // },
  ];

  public currentRoute: string | undefined;

  private routeSub: Subscription | null = null;

  constructor(
    public router: Router,
    public cd: ChangeDetectorRef,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService
  ) {
    this.routeSub = this.router.events.subscribe({
      next: () => {
        const parentRoute = this.getParentRoute(this.router.url);
        if (parentRoute) {
          this.currentRoute = parentRoute;
        } else {
          this.currentRoute = this.router.url;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  ngOnInit(): void {
    const parentRoute = this.getParentRoute(this.router.url);
    if (parentRoute) {
      this.currentRoute = parentRoute;
    } else {
      this.currentRoute = this.router.url;
    }
    // console.log(`initialState: currentRoute => ${this.currentRoute}`);
  }

  routeTo(route: string) {
    // this.currentRoute = this.router.url;
    // console.log(`currentRoute => ${this.currentRoute}`);
    this.router.navigateByUrl(route);
    // this.cd.detectChanges();
  }

  /**
   * Used to highlight the current route in navigation if the route is a subroute.
   * @param route the current route
   * @returns the 'id' of the parent route
   */
  getParentRoute(route: string): string | null {
    for (const rootElement of this.mainNavContent) {
      if (rootElement.childroutes?.includes(route)) {
        return rootElement.id;
      }
    }
    return null;
  }

  public OnB2CLoginClick() {
    if (this.msalGuardConfig.authRequest) {
      this.msalAuthService.loginRedirect();
    } else {
      this.msalAuthService.loginPopup();
    }
  }
}
