import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from '@features/dashboard/components/dashboard/dashboard.component';
import { SmartusComponentsModule } from '@affinis/smartus-components';
import { DashboardTicketCreateComponent } from './components/dashboard-ticket-create/dashboard-ticket-create.component';

@NgModule({
  declarations: [DashboardComponent, DashboardTicketCreateComponent],
  imports: [CommonModule, SmartusComponentsModule],
})
export class DashboardModule {}
