import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-animated-counter',
  templateUrl: './animated-counter.component.html',
  styleUrls: ['./animated-counter.component.scss'],
})
export class AnimatedCounterComponent implements AfterViewInit, OnChanges {
  @Input() duration = 0;
  @Input() counterNum = 100;
  @Input() steps = 12;
  @ViewChild('animatedCounter') animatedDigit?: ElementRef;

  animateCount() {
    if (this.animatedDigit) {
      this.counterFunc(this.counterNum, this.duration, this.animatedDigit);
    }
  }

  counterFunc(endValue: number, durationMs: number, element: ElementRef) {
    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = endValue / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0,
      currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  ngAfterViewInit() {
    if (this.counterNum) {
      this.animateCount();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.counterNum) {
      this.animateCount();
    }
  }
}
