import { NgModule } from '@angular/core';
import { FeedbackTelekomComponent } from '@mandants/telekom/features/feedback/feedback.component';
import { ImprintTelekomComponent } from '@mandants/telekom/features/imprint/imprint.component';
import { LocationModule } from '@mandants/telekom/features/location/location.module';
import { PrivacyTelekomComponent } from '@mandants/telekom/features/privacy/privacy.component';
import { TelekomRemModule } from '@mandants/telekom/features/rem/telekom-rem.module';
import { ProspectiveTenantsTelekomComponent } from '@mandants/telekom/features/tasks/prospective-tenants/prospective-tenants.component';
import { TaskDetailsTelekomComponent } from '@mandants/telekom/features/tasks/task-details/task-details.component';
import { TaskListTelekomComponent } from '@mandants/telekom/features/tasks/task-list/task-list.component';
import { TicketCreateOrderAccessTelekomComponent } from '@mandants/telekom/features/tickets/ticket-create/order-access/order-access.component';
import { TicketListTelekomComponent } from '@mandants/telekom/features/tickets/ticket-list/ticket-list.component';
import { TelekomSharedModule } from '@mandants/telekom/telekom-shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { SharedModule } from '@shared/shared.module';
import { AccessOrdererComponent } from '@mandants/telekom/features/access-orderer/access-orderer.component';
import { EditAccessOrdererComponent } from './features/access-orderer/edit-access-orderer/edit-access-orderer.component';
import { AccessOrdererHistoryComponent } from './features/access-orderer/access-orderer-history/access-orderer-history.component';

@NgModule({
  declarations: [
    TicketListTelekomComponent,
    TaskListTelekomComponent,
    TaskDetailsTelekomComponent,
    FeedbackTelekomComponent,
    ImprintTelekomComponent,
    PrivacyTelekomComponent,
    TicketCreateOrderAccessTelekomComponent,
    ProspectiveTenantsTelekomComponent,
    AccessOrdererComponent,
    EditAccessOrdererComponent,
    AccessOrdererHistoryComponent
  ],
  imports: [
    TelekomSharedModule,
    SharedModule,
    LocationModule,
    TelekomRemModule,
    TranslateModule,
    OverrideModule,
  ],
  exports: [],
  providers: [],
})
export class TelekomModule {}
