import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public footerNavContent = [
    {
      name: 'Impressum',
      id: 'Imprint',
      onClick: () => this.routeTo('/landing/imprint'),
    },
    {
      name: 'Datenschutz',
      id: 'Data privacy',
      icon: 'alert-imprint-dataprivacy',
      onClick: () => this.routeTo('/landing/privacy'),
    },
  ];

  constructor(public router: Router, public cd: ChangeDetectorRef) {}

  routeTo(route: string) {
    this.router.navigateByUrl(route);
    this.cd.detectChanges();
  }
}
