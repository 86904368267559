import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-description-wrapper',
  template: `
  <ng-container #fieldComponent></ng-container>
  <div class="description">{{ props.customDescription }}</div>
  `,
  styles: [
    `
    .description {
      margin-bottom: 35px; 
      padding: 0 16px;
      font-size: 12px;
    }
    `,
  ],
})
export class FormlyDescriptionWrapperComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) override fieldComponent!: ViewContainerRef;

  constructor(private renderer: Renderer2, private elRef: ElementRef) {
    super();
  }

  ngAfterViewInit() {
    const hostElement = this.elRef.nativeElement;

    const formlyWrapper = hostElement.querySelector('formly-wrapper-mat-form-field');

    if (formlyWrapper) {
      const matFormField = formlyWrapper.querySelector('mat-form-field');

      if (matFormField) {
        const targetDiv = matFormField.querySelector('.mat-mdc-form-field-subscript-wrapper');

        if (targetDiv) {
          this.renderer.setStyle(targetDiv, 'height', '0');
          this.renderer.setStyle(targetDiv, 'overflow', 'hidden');
        }
      }
    }
  }
}
