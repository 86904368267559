import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ArchObjInformationCategoryViewModel,
  ArchObjLocationInformationViewModel,
  LocationClient,
} from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationInfoCategory } from '../../location.constants';

@Component({
  selector: 'app-location-information-edit',
  templateUrl: './location-information-edit.component.html',
  styleUrls: ['./location-information-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationInformationEditComponent {
  public isLoading = false;
  public locationInfo: ArchObjLocationInformationViewModel;
  public title = '';
  public minDate: Date | null;
  public maxDate: Date | null;

  public categories: ArchObjInformationCategoryViewModel[] = [];
  public locationInfos = [];

  public form: FormGroup;

  public onUpdate = false;

  public linkLabel: string;
  public linkNameLabel: string;

  constructor(
    public dialogRef: MatDialogRef<LocationInformationEditComponent>,
    public locationClient: LocationClient,
    public toastr: ToastrService,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      locationInfo: ArchObjLocationInformationViewModel;
      title: string;
      update: boolean;
      categories: ArchObjInformationCategoryViewModel[];
      locationInfos: ArchObjLocationInformationViewModel[];
    }
  ) {
    this.title = data.title;
    this.locationInfo = { ...data.locationInfo };
    if(this.locationInfo?.text){
      this.locationInfo.text = this.locationInfo.text.replace(/<br>/g, '\n');
    }
    this.onUpdate = data.update;
    this.categories = data.categories;
    this.locationInfos = data.locationInfos;

    this.linkLabel = this.translateService.instant('location.myLocation.location_information.link');
    this.linkNameLabel = this.translateService.instant('location.myLocation.location_information.link_name');

    this.form = new FormGroup({
      category: new FormControl(null, Validators.required),
      validFrom: new FormControl(
        this.locationInfo.validFrom,
        Validators.required
      ),
      validTo: new FormControl(this.locationInfo.validTo),
      isPublished: new FormControl(this.locationInfo.isPublished ?? false, {
        nonNullable: true,
      }),
      link: new FormControl(this.locationInfo.link),
      linkName: new FormControl(this.locationInfo.linkName),
      text: new FormControl(this.locationInfo.text, Validators.required),
    });

    if (this.onUpdate) {
      this.form.get('category').disable();
    }

    if (this.locationInfo.category) {
      this.form
        .get('category')
        .setValue(
          this.categories.find(x => x.id === this.locationInfo.category.id)
        );
      this.setLinkValidator(this.locationInfo.category);
      this.toggleDateFormControls(this.locationInfo.category);
    }
  }

  setLinkValidator(category: ArchObjInformationCategoryViewModel) {
    if (
      category.id !== LocationInfoCategory.Contact &&
      category.id !== LocationInfoCategory.Reception
    ) {
      this.form
        .get('link')
        .setValidators(Validators.pattern('^(https?://).*$'));
    } else {
      this.form.get('link').setValidators(Validators.email);
    }
    this.form.get('link').updateValueAndValidity();
  }

  toggleDateFormControls(category: ArchObjInformationCategoryViewModel) {
    const validFrom = this.form.get('validFrom');
    const validTo = this.form.get('validTo');
    if (category.id === LocationInfoCategory.LocationHint) {
      validFrom.enable();
      validTo.enable();
    } else {
      validFrom.disable();
      validTo.disable();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const requestModel: ArchObjLocationInformationViewModel = {
        ...this.locationInfo,
        ...this.form.value,
      };

      this.isLoading = true;

      const locationInfoRequestObsv = this.onUpdate
        ? this.locationClient.postUpdateLocationInformation(requestModel)
        : this.locationClient.postAddLocationInformation(requestModel);

      locationInfoRequestObsv.subscribe({
        next: (response: any) => {
          this.handleSuccessWithMessage(
            response,
            this.translateService.instant(
              'crud.update.success.sucess_update_msg'
            )
          );
          this.dialogRef.close(true);
        },
        error: (error: any) => this.handleError(error),
      });
    }
  }

  onValidFromDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.minDate = event.value;
  }

  onValidToDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.maxDate = event.value;
  }

  dateFilter = (date: Date | null): boolean => {
    return !this.minDate || !date || date >= this.minDate;
  };

  private handleSuccessWithMessage(
    response: any,
    message: string,
    callback?: (response: any) => void
  ) {
    this.handleSuccess(response, callback);
    this.toastr.success(message);
  }

  private handleSuccess(response: any, callback?: (response: any) => void) {
    if (callback) {
      callback(response);
    }
    this.isLoading = false;
    console.log(response);
  }

  private handleError(error: any) {
    this.isLoading = false;
    console.log(error);
    const message = this.translateService.instant(
      'crud.update.error.failed_to_update_msg'
    );
    this.toastr.error(message);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public onCategoryOptionSelect(item: ArchObjInformationCategoryViewModel) {
    for (const controlName in this.form.controls) {
      if (controlName !== 'category') {
        this.form.get(controlName)?.reset();
      }
    }

    if (
      item.id !== LocationInfoCategory.LocationHint &&
      item.id != LocationInfoCategory.AdditionalServices
    ) {
      const foundLocationInfo = this.locationInfos.find(
        x => x.category?.id == item.id
      );
      if (foundLocationInfo) {
        this.onUpdate = true;
        this.locationInfo = { ...foundLocationInfo };
        console.log(foundLocationInfo);
        if(foundLocationInfo?.text){
          foundLocationInfo.text = this.locationInfo.text.replace(/<br>/g, '\n');
        }
        if (foundLocationInfo.isPublished) {
          this.form.get('isPublished').setValue(foundLocationInfo.isPublished);
        }
        if (foundLocationInfo.link) {
          this.form.get('link').setValue(foundLocationInfo.link);
        }
        if (foundLocationInfo.linkName) {
          this.form.get('linkName').setValue(foundLocationInfo.linkName);
        }
        if (foundLocationInfo.text) {
          this.form.get('text').setValue(foundLocationInfo.text);
        }
      }
    }
    
    if(
      item.id == LocationInfoCategory.Reception ||
      item.id == LocationInfoCategory.Contact
    ) {
      this.linkLabel = this.translateService.instant('location.myLocation.location_information.mail');
      this.linkNameLabel = this.translateService.instant('location.myLocation.location_information.mail_name');
    } else {
      this.linkLabel = this.translateService.instant('location.myLocation.location_information.link');
      this.linkNameLabel = this.translateService.instant('location.myLocation.location_information.link_name');
    }
    this.setLinkValidator(item);
    this.toggleDateFormControls(item);
  }
}
