import { MsalService } from '@azure/msal-angular';
import { UserClient } from '@core/api';
import { FormBaseComponent } from '../forms/form-base/form-base';
import { Injector } from '@angular/core';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from '@core/auth-config';
import { ToastrService } from 'ngx-toastr';

export interface MyAccountInfo extends AccountInfo {
  idTokenClaims: {
    given_name: string;
    family_name: string;
    extension_CustomerID: string;
    extension_ReferenceUserID: string;
    groups: [];
  };
}

export class ProfileBaseComponent extends FormBaseComponent {
  userClient: UserClient;

  msalService: MsalService;

  toastr: ToastrService;

  constructor(injector: Injector) {
    super(injector);
    this.userClient = injector.get(UserClient);
    this.msalService = injector.get(MsalService);
    this.toastr = injector.get(ToastrService);
  }

  getUser(userId: string) {
    const account = this.msalService.instance.getActiveAccount();
    return this.userClient.readUser({
      tenantId: account?.tenantId,
      mandantId: (<MyAccountInfo>account)?.idTokenClaims?.extension_CustomerID,
      userId,
    });
  }

  getAvailableGroups() {
    const account = this.msalService.instance.getActiveAccount();
    return this.userClient.readGroups({ tenantId: account?.tenantId });
  }

  resetPassword() {
    const editProfileFlowRequest: RedirectRequest = {
      scopes: ['openid'],
      authority: b2cPolicies.authorities.editProfile.authority,
    };
    this.msalService.loginRedirect(editProfileFlowRequest);
  }

  showError() {
    this.loading = false;
    const message = this.translateService.instant(
      'user_detail.form.error.message'
    );
    this.toastr.error(message);
  }
}
