import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-chapter',
  styles: [
    `
      .chapter-headline {
        margin-top: 20px;
      }
    `,
  ],
  template: `
    <hr *ngIf="field.props?.chapterHr" />
    <h3
      class="chapter-headline"
      [innerText]="field.props?.chapterTitle || ''"
      #tooltip="matTooltip"
      [matTooltip]="field.props?.tooltip"></h3>
    <su-button
      *ngIf="field.props?.tooltip"
      [skin]="'accent'"
      [icon]="'question-circle'"
      (onClick)="tooltip.toggle()"></su-button>
    <p [innerText]="field.props?.chapterText || ''"></p>
  `,
})
export class FormlyChapterComponent extends FieldType {}
