import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BaseSearchOptions,
  IncidentClient,
  OmContact,
  TaskClient,
  TaskSearchOptions,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalFilterComponent } from '@shared/components/modal-filter/modal-filter.component';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import { ITableConfig } from '@shared/components/table-base/table-base.types';
import { addressFormatter } from '@shared/utils/address.formatter';
import { ButtonProps } from '@affinis/smartus-components/lib/button/button.types';
import { Chip } from '@affinis/smartus-components/lib/chips/chips.component.types';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: [
    '../../../../../app/shared/components/table-base/table-base.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListTelekomComponent
  extends TableBaseComponent
  implements OnInit
{
  override config = this.configure(<ITableConfig>{
    title: 'taskmodule.task.list.title',
    translatePath: 'taskmodule.shared',
  });

  form = new FormGroup({});
  user: OmContact;

  controlButtons: ButtonProps[] = [
    {
      label: this.translateService.instant('crud.refresh'),
      onClick: () => {
        this.refresh();
      },
    },
  ];

  organisations: string[] = [
    'ISS Vermietung',
    'ISS Property Management',
    'ISS An-/Abmietung',
    'ISS Capital Projects',
    'ISS Vertragsmanagement',
  ];

  constructor(
    injector: Injector,
    public incidentClient: IncidentClient,
    private breadcrumbsService: BreadcrumbService,

    private taskClient: TaskClient
  ) {
    super(injector);
  }

  public ngOnInit() {
    this.breadcrumbsService.setBreadcrumbs([
      {
        label: this.translateService.instant('taskmodule.task.list.title'),
      },
    ]);

    this.route.queryParams.subscribe({
      next: params => {
        this.mapFormModelToChips(params);
        // this.updateFilter(params)
        //   this.refreshFromFilter();
        // this.dialogData.filterValues = this.config.grid.filter.filterOptions;
      },
    });

    this.userService.isGsusUserBehavior$.subscribe({
      next: isGsus => {
        this.sysUser = isGsus;
        if (this.sysUser) {
          this.columns.push({
            header: this.translate('label__organisation'),
            field: 'organisation.name',
            sortable: true,
            class: 'truncated',
            resizable: true,
            hide: true,
          });
          this.filterFields[0].fieldGroup.push({
            type: 'select',
            key: 'organisation',
            props: {
              label: this.translate('label__organisation'),
              options: [
                {
                  label: this.organisations[0],
                  value: this.organisations[0],
                },
                {
                  label: this.organisations[1],
                  value: this.organisations[1],
                },
                {
                  label: this.organisations[2],
                  value: this.organisations[2],
                },
                {
                  label: this.organisations[3],
                  value: this.organisations[3],
                },
                {
                  label: this.organisations[4],
                  value: this.organisations[4],
                },
              ],
            },
          });
        }
      },
    });
  }

  chips: Chip[] = [];

  override columns: MtxGridColumn[] = [
    {
      field: 'showExpand',
      showExpand: true,
      sortable: false,
      resizable: false,
      width: '90px',
    },
    {
      header: this.translate('label__ticketNumber'),
      field: 'incidentTicketNumber',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
    {
      header: this.translate('label__incidentTitle'),
      field: 'incidentTitle',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
    {
      header: this.translate('label__editor'),
      field: 'taskProcessorName',
      sortable: true,
      class: 'truncated',
      resizable: true,
      hide: true,
    },
    {
      header: this.translate('label__incidentDescription'),
      field: 'incidentDescription',
      sortable: true,
      class: 'truncated',
      resizable: true,
      minWidth: 195,
      hide: true,
    },
    {
      header: this.translate('label__incidentClass'),
      field: 'incidentTicketClass',
      sortable: true,
      class: 'truncated',
      resizable: true
    },
    {
      header: this.translate('label__economicUnit'),
      field: 'incidentEconomicUnit',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
    {
      header: this.translate('label__postalCode'),
      field: 'incidentPostalCode',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
    {
      header: this.translate('label__incidentCity'),
      field: 'incidentCity',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
    {
      header: this.translate('label__incidentStreet'),
      field: 'incidentStreet',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
    {
      header: this.translate('label__subject'),
      field: 'taskSubject',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
    {
      header: this.translate('label__taskDescription'),
      field: 'taskDescription',
      sortable: true,
      class: 'truncated',
      resizable: true,
      minWidth: 200,
      maxWidth: 10000,
    },
    {
      header: this.translate('label__status'),
      field: 'taskStatus',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
    {
      header: this.translate('label__dueDate'),
      field: 'taskDueDate',
      sortable: true,
      class: 'truncated',
      formatter: (_data: any) => '--',
      // TODO Reimplement this if use of dueDate is determined https://dev.azure.com/pts-vsts/Magenta%20Squad/_workitems/edit/19247
      // data.dueDate
      //   ? moment(data.dueDate)
      //       .locale(this.translateService.instant('locale.locale'))
      //       .format(
      //         this.translateService.instant('locale.moment__date_format')
      //       )
      //   : '--',
      resizable: true,
    },
    {
      header: this.translate('label__result'),
      field: 'taskResult',
      sortable: true,
      class: 'truncated',
      resizable: true,
    },
  ];

  override fieldsInExpansionTemplate = ['taskDescription'];

  override filterFields: Array<FormlyFieldConfig> = [
    {
      type: 'flex-layout',
      fieldGroup: [
        {
          key: 'ticketNumber',
          type: 'input',
          props: {
            label: this.translate('label__ticketNumber'),
          },
        },
        {
          key: 'incidentTitle',
          type: 'input',
          props: {
            label: this.translate('label__title'),
          },
        },
        {
          key: 'externalProcessor',
          type: 'input',
          props: {
            label: this.translate('label__editor'),
          },
        },
        {
          key: 'incidentDescription',
          type: 'input',
          props: {
            label: this.translate('label__incidentDescription'),
          },
        },
        {
          key: 'incidentClass',
          type: 'input',
          props: {
            label: this.translate('label__incidentClass'),
          },
        },
        {
          key: 'economicUnit',
          type: 'input',
          props: {
            label: this.translate('label__economicUnit'),
          },
        },
        {
          key: 'postalcode',
          type: 'input',
          props: {
            label: this.translate('label__postalCode'),
          },
        },
        {
          key: 'city',
          type: 'input',
          props: {
            label: this.translate('label__incidentCity'),
          },
        },
        {
          key: 'streetName',
          type: 'input',
          props: {
            label: this.translate('label__incidentStreet'),
          },
        },
        {
          key: 'subject',
          type: 'input',
          props: {
            label: this.translate('label__subject'),
          },
        },
        {
          key: 'description',
          type: 'input',
          props: {
            label: this.translate('label__taskDescription'),
          },
        },
        {
          key: 'status',
          type: 'input',
          props: {
            label: this.translate('label__status'),
          },
        },
        {
          type: 'matdatetimepicker',
          key: 'dueDateFrom',
          props: {
            label: this.translate('label__dueDateFrom'),
            // startAt: moment(new Date()).startOf('day'),
            type: 'date',
          },
        },
        {
          type: 'matdatetimepicker',
          key: 'dueDateTo',
          props: {
            label: this.translate('label__dueDateTo'),
            // startAt: moment(new Date()).startOf('day'),
            type: 'date',
          },
        },
        {
          type: 'input',
          key: 'resultText',
          props: {
            label: this.translate('label__resultText'),
          },
        },
      ],
    },
  ];

  override getTableData$ = (query?: BaseSearchOptions, filterOptions?: any) => {
    const searchOptions: TaskSearchOptions = {
      ...query,
      filterOptions,
    };
    return this.taskClient.getTasks(searchOptions).pipe(
      map(e => {
        const filteredEntities = e.entites.filter(et => et.incidentTicketClass !== 'Gebäudestörung');
        return {
          ...e,
          entites: filteredEntities.map(et => {
            return {
              ...et,
              ticketNumber: et.incidentTicketNumber,
            };
          }),
        };
      })
    );
  };

  modalComponent = ModalFilterComponent;

  dialogData = {
    columns: this.columns,
    filterFields: this.filterFields,
    filterValues: this.config.grid.filter.filterOptions,
    actions: {
      onSubmit: (formModel: any) => {
        this.mapFormModelToChips(formModel);
        this.updateFilter(formModel);
        this.refreshFromFilter();
        this.dialogData.filterValues = this.config.grid.filter.filterOptions;
      },
      onReset: () => {
        this.updateFilter({});
        this.refreshFromFilter();
        window.history.replaceState({}, '', `/tasks`);
        this.mapFormModelToChips({});
      },
    },
  };

  onCloseDialog = (e: any) => {
    console.log('[Ticket list] onCloseDialog', e);
  };

  override clickRow = (data, router: Router) => {
    if (this.sysUser) {
      router.navigate(['/task/viewDetails/', data.rowData.taskId]);
    } else {
      router.navigate(['/task/details/', data.rowData.taskId]);
    }
  };

  handleOnRemoveChip = (eventObj: any) => {
    delete this.config.grid.filter.filterOptions[eventObj.item.id];
    if (eventObj.item.id === 'ticketNumber') {
      window.history.replaceState({}, '', `/tasks`);
    }
    this.chips = this.chips.filter(
      chipItem => chipItem.id !== eventObj.item.id
    );
    this.refreshFromFilter();
  };

  mapFormModelToChips = formModel => {
    this.chips = [];
    this.chips.length = 0;

    Object.keys(formModel).forEach(formModelItemKey => {
      const field = this.filterFields[0].fieldGroup.find(fieldItem => {
        return fieldItem.key === formModelItemKey;
      });

      if (field) {
        if (
          formModelItemKey === 'dueDateFrom' ||
          formModelItemKey === 'dueDateTo'
        ) {
          const date = new Date(formModel[formModelItemKey]).toLocaleDateString(
            'de-DE'
          );
          this.chips.push({
            id: formModelItemKey,
            label: `${field.props.label}: ${date}`,
          });
        } else {
          if (formModel[formModelItemKey]) {
            this.chips.push({
              id: formModelItemKey,
              label: `${field.props.label}: ${formModel[formModelItemKey]}`,
            });
          }
        }
      }
    });
  };
}
