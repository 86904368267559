import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AccessOrdererClient } from '@core/api';
import { FormlyFieldBaseService } from '@core/services/formly-service/formly-field.service';
import { FormlyPreviewAutocompleteProps } from '../formly-service/formly-service.types';

@Injectable({
  providedIn: 'root',
})
export class CostcenterSearchFormlyFieldService extends FormlyFieldBaseService {
  override translatePath = 'costcenter.autocomplete';

  constructor(
    protected override translateService: TranslateService,
    private accessOrdererClient: AccessOrdererClient,
  ) {
    super(translateService);
  }

  autocompleteSearch(
    placeholder: string,
    required?: boolean,
    visibleInConfirm?: boolean,
    currentUserEmail?: string,
    onSelect?: (id: string) => void,
    onDelete?: () => void,
  ): FormlyFieldConfig[] {
    const columnDefinitions = [
      {
        fieldName: 'costCenter',
        labelTranslationKey: this.translatePath + '.costCenter',
        classes: 'col-3',
      },
      {
        fieldName: 'designation',
        labelTranslationKey: this.translatePath + '.designation',
        classes: 'col-2',
      }
    ];
    const props: FormlyPreviewAutocompleteProps = {
      bindLabel: 'fullName',
      bindValue: 'id',
      required: required,
      visibleInConfirm: visibleInConfirm ?? false,
      translatePath: this.translatePath,
      columnDefinitions,
      defaultValue: { id: currentUserEmail },
      onSearch: (searchText: string): Observable<any> => {
        return this.accessOrdererClient.searchManagerCostCenters(searchText);
      },
      onSelect,
      onDelete,
      label: this.translateService.instant(placeholder),
    };

    return [
      {
        type: 'preview-autocomplete',
        key: 'searchResult',
        props: { ...props },
        validation: {
          messages: {
            required: this.translateService.instant('formErrors.costcenter.empty'),
          },
        },
      },
    ];
  }
}
