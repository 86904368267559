 <su-headline h="h1" skin="primary">{{ title | translate }}</su-headline>

 <su-slot-tabs [tabs]="tabs" [activeTab]="activeTab"></su-slot-tabs>

<su-slot-tab-content *ngIf="activeTab === tabs[0].id">
  <su-card [card]="cardSummary">
    <div card-content data-debug="form-confirm" *ngIf="(ticketData$ | async) as ticketData">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'taskmodule.shared.label__ticketNumber', value1: ticketData.ticketNumber}"></ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'taskmodule.shared.label__incidentType', value1: ticketData.requestType}"></ng-container>
      
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.confirm.label__incidentTitle', value1: ticketData.name}"></ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.confirm.label__incidentDescription', value1: ticketData.description}"></ng-container>
      <br>
      <su-headline skin="secondary" h="h2">{{ 'ticket.create.confirm.headline__objectAdress' | translate }}</su-headline>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.confirm.label__placeholder', value1: ticketData.archObjId, value2: ticketData.city, value3: ticketData.street}"></ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.confirm.label__roomNumber', value1: ticketData.room}"></ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.confirm.label__floor', value1: ticketData.floor}"></ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.confirm.label__section', value1: ticketData.structuralPart}"></ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.confirm.label__differentAddress', value1: ticketData.deviatingAddress}"></ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.headline__search_creator', value1: ticketData.creatorName, value2: ticketData.creatorEmail}"></ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {label: 'ticket.create.headline__search_processor', value1: ticketData.reporterName, value2: ticketData.reporterEmail}"></ng-container>
    </div>
  </su-card>
</su-slot-tab-content>

<su-slot-tab-content *ngIf="activeTab === tabs[1].id">
  <su-card [card]="cardDocuments" *ngIf="(ticketData$ | async) as ticketData">
    <div card-content>
      <ng-container>
        <mtx-grid
          #grid
          [columns]="columns"
          [data]="ticketData.documents"
          [rowStriped]="true"
          [noResultText]="'grid.no_results' | translate"
          [cellSelectable]="false">
        </mtx-grid>
      </ng-container>
    </div>
  </su-card>
</su-slot-tab-content>

<ng-template #itemTemplate let-label="label" let-value1="value1" let-value2="value2" let-value3="value3">
  <div class="item-container">
    <div class="confirm-label">
      <span>{{ label | translate }}</span>:
    </div>
    <span class="confirm-value" *ngIf="value1">
      <ng-container>{{ value1 }}</ng-container>
      <ng-container *ngIf="value2">, {{ value2 }}</ng-container>
      <ng-container *ngIf="value3">, {{ value3 }}</ng-container>
    </span>
    <span class="confirm-value" *ngIf="!value1">--</span>
  </div>
</ng-template>

<ng-template
  #documentActionsTemplate
  let-row
  let-index="index"
  let-col="colDef">
  <div class="button-container">
    <su-button
      skin="secondary"
      icon="download"
      [title]="'Download'"
      (onClick)="downloadDocument($event, row)">
    </su-button>
  </div>
</ng-template>