import { MenuItem } from '@affinis/smartus-components';
import { ButtonProps } from '@affinis/smartus-components/lib/button/button.types';
import { Chip } from '@affinis/smartus-components/lib/chips/chips.component.types';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSearchOptions, BuildingFaultIncidentRequest, IncidentClient } from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalFilterComponent } from '@shared/components/modal-filter/modal-filter.component';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import { ITableConfig, ITableFilterConfig } from '@shared/components/table-base/table-base.types';
import * as moment from 'moment';

export interface IDialogData {
  columns: MtxGridColumn[],
  filterFields: FormlyFieldConfig[],
  filterValues: ITableFilterConfig,
  actions: {
    onSubmit: (formModel: any) => void,
    onReset: () => void
  }
}

@Component({
  selector: 'app-ticket-list-helpdesk',
  templateUrl: './ticket-list-helpdesk.component.html',
  styleUrls: [],
})
export class TicketListHelpdeskComponent
  extends TableBaseComponent
  implements OnInit
{
  override config = this.configure(<ITableConfig>{
    title: 'taskmodule.tickets.list.title',
    translatePath: 'helpdesk.ticket.list',
  });

  controlButtons: ButtonProps[] = [
    {
      label: this.translateService.instant('crud.refresh'),
      onClick: () => {
        this.refresh();
      },
    },
  ];

  chips: Chip[] = [];

  menuItems: MenuItem[] = [];

  dialogData: IDialogData;

  constructor(
    injector: Injector,
    public incidentClient: IncidentClient,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  public ngOnInit() {
    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.translateService.instant('ticket.create.helpdesk.my_tickets'),
      },
    ]);

    this.columns = [
      {
        header: this.translate('ticketNumber'),
        field: 'ticketNumber',
        sortable: true,
        class: '',
        resizable: true,
      },
      {
        header: this.translate('title'),
        field: 'name',
        sortable: true,
        class: 'truncated',
        resizable: true,
        width: '200dp',
        minWidth: 200,
      },
      {
        header: this.translate('description'),
        field: 'description',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
        hide: true
      },
      {
        header: this.translate('address'),
        field: 'addressString',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translate('room'),
        field: 'room',
        sortable: true,
        class: '',
        resizable: true,
      },
      {
        header: this.translate('floor'),
        field: 'floor',
        sortable: true,
        class: 'truncated',
        resizable: true,
        hide: true
      },
      {
        header: this.translate('structuralPart'),
        field: 'structuralPart',
        sortable: true,
        class: 'truncated',
        resizable: true,
        hide: true
      },
      {
        header: this.translate('deviatingAddress'),
        field: 'deviatingAddress',
        sortable: true,
        class: 'truncated',
        resizable: true,
        hide: true
      },
      {
        header: this.translate('createdOn'),
        field: 'createdOn',
        sortable: true,
        class: 'truncated',
        formatter: (data: any) =>
          data.createdOn
            ? moment(data.createdOn)
                .locale(this.translateService.instant('locale.locale'))
                .format(
                  this.translateService.instant(
                    'locale.moment__date_time_format'
                  )
                ) +
              ' ' +
              this.translate('extended_time_str')
            : '--',
        resizable: true,
        width: '140px',
        minWidth: 134,
      },
    ];

    
    this.filterFields = [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'ticketNumber',
            type: 'input',
            props: {
              label: this.translate('ticketNumber'),
            },
          },
          {
            key: 'name',
            type: 'input',
            props: {
              label: this.translate('title'),
            },
          },
          {
            key: 'description',
            type: 'input',
            props: {
              label: this.translate('description'),
            },
          },
          {
            key: 'address',
            type: 'input',
            props: {
              label: this.translate('address'),
            },
          },
          {
            key: 'room',
            type: 'input',
            props: {
              label: this.translate('room'),
            },
          },
          {
            key: 'floor',
            type: 'input',
            props: {
              label: this.translate('floor'),
            },
          },
          {
            key: 'structuralPart',
            type: 'input',
            props: {
              label: this.translate('structuralPart'),
            },
          },
          {
            key: 'deviatingAddress',
            type: 'input',
            props: {
              label: this.translate('deviatingAddress'),
            },
          },
          {
            type: 'matdatetimepicker',
            key: 'createDateFrom',
            props: {
              label: this.translate('createDateFrom'),
              startAt: moment(new Date()).startOf('day'),
              type: 'date',
            },
          },
          {
            type: 'matdatetimepicker',
            key: 'createDateTo',
            props: {
              label: this.translate('createDateTo'),
              startAt: moment(new Date()).startOf('day'),
              type: 'date',
            },
          },
        ],
      },
    ];

    this.dialogData = {
      columns: this.columns,
      filterFields: this.filterFields,
      filterValues: this.config.grid.filter.filterOptions,
      actions: {
        onSubmit: (formModel) => {
          this.mapFormModelToChips(formModel);
          this.updateFilter(formModel);
          this.refreshFromFilter();
          this.dialogData.filterValues = this.config.grid.filter.filterOptions;
        },
        onReset: () => {
          this.mapFormModelToChips({});
          this.updateFilter({});
          this.refreshFromFilter();
        },
      },
    };
  }

  modalComponent = ModalFilterComponent;

  override columns: MtxGridColumn[] = [];

  override getTableData$ = (
    query?: BaseSearchOptions,
  ) => {
    const request: BuildingFaultIncidentRequest = {
      isUserFiltered: true,
      pagingOptions: query.pagingOptions,
      filterOptions: {
        incidentTicketNumber: this.config.grid.filter.filterOptions.ticketNumber,
        incidentName: this.config.grid.filter.filterOptions.name,
        incidentDescription: this.config.grid.filter.filterOptions.description,
        incidentAddress: this.config.grid.filter.filterOptions.address,
        generalRequestRoom: this.config.grid.filter.filterOptions.room,
        generalRequestFloor: this.config.grid.filter.filterOptions.floor,
        generalRequestStructuralPart: this.config.grid.filter.filterOptions.structuralPart,
        generalRequestDeviatingAddress: this.config.grid.filter.filterOptions.deviatingAddress,
        dateRangeStart: this.config.grid.filter.filterOptions.createDateFrom,
        dateRangeEnd: this.config.grid.filter.filterOptions.createDateTo
      },
      orderOptions: query.orderOptions
    };
    return this.incidentClient.getBuildingFaultIncidents(request);
  };

  override clickRow = (data, router: Router) => {
    router.navigate(['helpdesk/my-tickets/' + data.rowData.incidentId]);
  };

  onCloseDialog = (e: any) => {
    console.log('[Ticket list] onCloseDialog', e);
  };

  handleOnRemoveChip = (eventObj: any) => {
    delete this.config.grid.filter.filterOptions[eventObj.id];
    this.chips = this.chips.filter(
      chipItem => chipItem.id !== eventObj.id
    );
    this.refreshFromFilter();
  };

  mapFormModelToChips = formModel => {
    this.chips = [];
    this.chips.length = 0;

    Object.keys(formModel).forEach(formModelItemKey => {
      const field = this.filterFields[0].fieldGroup.find(fieldItem => {
        return fieldItem.key === formModelItemKey;
      });
      if (field) {
        if (formModelItemKey === 'ticketTaskSelection') {
          const option = (field.props.options as any[]).find(
            opt => opt.value === formModel[formModelItemKey]
          );
          if (option) {
            if (this.sysUser) {
              const existingChip = this.chips.find(
                chip => chip.id === formModelItemKey
              );
              if (existingChip) {
                this.chips.splice(this.chips.indexOf(existingChip), 1, {
                  id: formModelItemKey,
                  label: `${field.props.label}: ${option.label}`,
                });
              } else {
                this.chips.push({
                  id: formModelItemKey,
                  label: `${field.props.label}: ${option.label}`,
                });
              }
            } else {
              this.chips.push({
                id: formModelItemKey,
                label: `${field.props.label}: ${option.label}`,
              });
            }
          }
        } else if (
          formModelItemKey === 'createDateTo' ||
          formModelItemKey === 'createDateFrom'
        ) {
          const date = new Date(formModel[formModelItemKey]).toLocaleDateString(
            'de-DE'
          );
          this.chips.push({
            id: formModelItemKey,
            label: `${field.props.label}: ${date}`,
          });
        } else {
          if (formModel[formModelItemKey]) {
            this.chips.push({
              id: formModelItemKey,
              label: `${field.props.label}: ${formModel[formModelItemKey]}`,
            });
          }
        }
      }
    });
  };
}
