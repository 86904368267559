import { Component } from '@angular/core';

@Component({
  selector: 'app-fact-wrapper',
  templateUrl: './fact-wrapper.component.html',
  styleUrls: ['./fact-wrapper.component.scss'],
})
export class FactWrapperComponent {
  factItemData: FactItemData[] = [
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '9000',
      subtitle: 'Immobilien',
      factType: '',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '7000',
      subtitle: 'Eigentumsobjekte',
      factType: '',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '8',
      subtitle: 'Gesamtfläche',
      factType: 'm2',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '2',
      subtitle: 'Bürofläche',
      factType: 'm2',
    },
  ];
}

export interface FactItemData {
  iconUrl: string;
  factAmount: string;
  subtitle: string;
  factType: string;
}
