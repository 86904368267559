<div
  class="header-bg-home"
  [ngStyle]="{ 'background-image': 'url(' + img + ')' }">
  <div class="content-body">
    <div class="textbox">
      <h3>{{ headertitle }}</h3>
      <h1>{{ title }}</h1>
      <div class="button-wrapper-container">
        <app-button-wrapper
          [routerLink]="'/landing/contact'"
          [buttonVariant]="'primary'"
          [buttonText]="'Kontakt aufnehmen'">
        </app-button-wrapper>
      </div>
      <p><br /><br /></p>
    </div>
  </div>
</div>
