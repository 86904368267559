import { Component } from '@angular/core';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyTelekomComponent {
  constructor(private bradcrubService: BreadcrumbService) {
    this.bradcrubService.setBreadcrumbs([
      {
        label: 'Datenschutz',
      },
    ]);
  }
}
