import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export function fileAttachments(
  translateService: TranslateService
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__attachment'
            ),
            visibleInConfirm: true
          },
        },
        {
          key: 'attachments',
          type: 'repeat',
          props: {
            maxItems: 10,
            addText: translateService.instant(
              'ticket.create.button__attach_new_file'
            ),
            tooltip: translateService.instant('ticket.create.p__info_attachment'),
          },

          fieldArray: {
            type: 'flex-layout-col',

            fieldGroup: [
              {
                type: 'select',
                key: 'attachmentType',
                id: 'fileAttachment',
                props: {
                  label: translateService.instant(
                    'ticket.create.select__type_of_file'
                  ),
                  required: true,
                  visibleInConfirm: true,
                  options: [
                    { label: 'Flurstückskarte', value: 'AUG' },
                    { label: 'Grundbuchauszug', value: 'AUG' },
                    { label: 'Pläne', value: 'PL7' },
                    { label: 'Schriftverkehr', value: 'SVK' },
                    { label: 'Sonstiges', value: 'SO7' },
                  ].sort((a, b) => {
                    if (a.label === b.label) {
                      return 0;
                    }
                    return a.label < b.label ? -1 : 1;
                  }),
                },
              },
              {
                type: 'file',
                key: 'attachment',
                props: {
                  label: translateService.instant(
                    'ticket.create.label__attachment'
                  ),
                  visibleInConfirm: true,
                  accept:
                    '.jpg, .jpeg, .png, .pdf, .txt, .tif, .tiff, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .msg, .zip',
                  maxFileSizeInMB: '10',
                  required: true,
                },
              },
            ],
          },
        },
      ]
    }
  ];
}
