<div class="who-page">
  <app-quick-text
    [title]="'Über uns'"
    [imgUrl]="'assets/images/undraw_select_house_qbag-green.svg'"
    [backgroundColor]="'#E20074'"
    [fontColor]="'white'"
    [hasLargeHeader]="true"
    [index]="1">
    <p>
      Telekom RealEstate erbringt Dienstleistungen im Bereich Asset Management
      und Portfoliomanagement für alle angemieteten und im Eigentum befindlichen
      Objekte in Deutschland für die Deutsche Telekom AG.
    </p>
  </app-quick-text>

  <div class="content-body margin-left-130">
    <div class="column-container">
      <div class="text-col">
        <h1>Wir geben Menschen Raum.</h1>
      </div>

      <div class="text-col">
        <p>
          Die ganzheitliche Digitalisierung und die nachhaltige Bewirtschaftung
          des Immobilienportfolios sind für Telekom RealEstate zentrale
          Herausforderungen.
        </p>
      </div>
      <div class="text-col"></div>
    </div>
  </div>

  <app-our-tasks> </app-our-tasks>

  <app-our-challenges> </app-our-challenges>

  <div
    class="header-bg"
    [ngStyle]="{
      'background-image':
        'url(assets/images/telekom-real-estate-podcast-banner-1500px.jpg)'
    }">
    <div class="content-body margin-left-130">
      <div class="textbox">
        <h2>Jetzt reinhören</h2>
        <div>
          <p>
            In unserem Podcast sprechen wir über aktuelle Themen und
            Herausforderungen im REM.
          </p>
        </div>
        <div class="button-wrapper-container">
          <scale-button
            [variant]="'primary'"
            href="https://open.spotify.com/show/5nSfhF0DRF4rMeOQFBocPA"
            target="_blank">
            Zum Podcast<mat-icon style="font-size: 20px">launch</mat-icon>
          </scale-button>
        </div>
      </div>
    </div>
  </div>
</div>
