import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-repeat-section',
  styles: [
    `
      .formly-repeat {
        display: flex;
        justify-content: space-between;
      }
      .formly-repeat-remove-button-container {
        margin-top: 10px;
      }
      .formly-repeat-section_field {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .button-container {
        display: flex;
        gap: 10px;
      }
      .button-container + .warning {
        margin-top: 10px;
      }
    `,
  ],
  template: `
    <div
      *ngFor="let field of field.fieldGroup; let i = index"
      class="formly-repeat">
      <formly-field
        [field]="field"
        class="formly-repeat-section_field"></formly-field>

      <div class="formly-repeat-remove-button-container">
        <su-button [skin]="'secondary'" (onClick)="remove(i)" [icon]="'remove'">
          <!-- color: red/warn  ; [style.margin-top.px]="16"-->
        </su-button>
      </div>
    </div>
    <div class="button-container">
      <su-button
        [skin]="'accent'"
        [icon]="'add'"
        (onClick)="add()"
        #tooltip="matTooltip"
        [matTooltip]="field.props?.tooltip"
        [disabled]="!(!to.maxItems || to.maxItems > field.fieldGroup!.length)"
        [label]="to.addText"></su-button>
      <su-button
        *ngIf="field.props?.tooltip"
        [skin]="'secondary'"
        [icon]="'question'"
        (onClick)="tooltip.toggle()"></su-button>
    </div>
    <p
      class="warning"
      color="warn"
      *ngIf="!(!to.maxItems || to.maxItems > field.fieldGroup!.length)"
      [innerText]="
        'ticket.create.p__error_max_attachments'
          | translate : { max: to.maxItems }
      "></p>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {}
