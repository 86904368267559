import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { defaultRoutes } from '@core/routing/routes';
import { RoutePath } from '@core/routing/routing.types';
import { MaintenanceGuard } from '@core/services/menu-service/maintenance.guard';
import { RolesGuard } from '@core/services/menu-service/roles.guard';
import { AuthGuard } from '@core/services/oauth-service/auth.guard';
import { CallbackComponent } from '@features/authentication/callback/callback.component';
import { DashboardTicketCreateComponent } from '@features/dashboard/components/dashboard-ticket-create/dashboard-ticket-create.component';
import { DashboardComponent } from '@features/dashboard/components/dashboard/dashboard.component';
import { DocumentsComponent } from '@features/documents/documents.component';
import { ProfileDefaultComponent } from '@features/profile-default/components/profile-default.component';
import { TaskDetailsViewComponent } from '@features/tasks/components/task-details-view/task-details-view.component';
import { TicketGetStatusDefaultComponent } from '@features/tickets/components/ticket-get-status/default/ticket-get-status-default';
import { TicketListHelpdeskComponent } from '@features/tickets/components/ticket-list-helpdesk/ticket-list-helpdesk.component';
import { TicketSummaryHelpdeskComponent } from '@features/tickets/components/ticket-list-helpdesk/ticket-summary-helpdesk/ticket-summary-helpdesk.component';
import { FeedbackTelekomComponent } from '@mandants/telekom/features/feedback/feedback.component';
import { ImprintTelekomComponent } from '@mandants/telekom/features/imprint/imprint.component';
import { MyLocationComponent } from '@mandants/telekom/features/location/my-location/my-location.component';
import { PrivacyTelekomComponent } from '@mandants/telekom/features/privacy/privacy.component';
import { ContactComponent } from '@mandants/telekom/features/rem/pages/contact/contact.component';
import { HomeComponent } from '@mandants/telekom/features/rem/pages/home/home.component';
import { ImpressumComponent } from '@mandants/telekom/features/rem/pages/impressum/impressum.component';
import { MaintenanceComponent } from '@mandants/telekom/features/rem/pages/maintenance/maintenance.component';
import { PortfolioComponent } from '@mandants/telekom/features/rem/pages/portfolio/portfolio.component';
import { PrivacyComponent } from '@mandants/telekom/features/rem/pages/privacy/privacy.component';
import { StatusItemComponent } from '@mandants/telekom/features/rem/pages/status/status-item.component';
import { StatusComponent } from '@mandants/telekom/features/rem/pages/status/status.component';
import { WhoComponent } from '@mandants/telekom/features/rem/pages/who/who.component';
import { RemComponent } from '@mandants/telekom/features/rem/rem.component';
import { TaskDetailsTelekomComponent } from '@mandants/telekom/features/tasks/task-details/task-details.component';
import { TaskListTelekomComponent } from '@mandants/telekom/features/tasks/task-list/task-list.component';
import { TicketCreateOrderAccessTelekomComponent } from '@mandants/telekom/features/tickets/ticket-create/order-access/order-access.component';
import { TicketCreateTelekomComponent } from '@mandants/telekom/features/tickets/ticket-create/ticket-create.component';
import { TicketListTelekomComponent } from '@mandants/telekom/features/tickets/ticket-list/ticket-list.component';
import { TicketStatusItemTelekomComponent } from '@mandants/telekom/features/tickets/ticket-status-item/ticket-status-item.component';
import { TelekomModule } from '@mandants/telekom/telekom.module';
import { AccessOrdererComponent } from '@mandants/telekom/features/access-orderer/access-orderer.component';

// Telekom routes
const routes: Routes = [
  {
    path: RoutePath.ACCESS_ORDERER,
    component: AccessOrdererComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.AUTH_CALLBACK,
    component: CallbackComponent,
    canActivate: [MsalGuard],
  },
  {
    path: RoutePath.DASHBOARD,
    component: DashboardComponent,
    canActivate: [AuthGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.DOCUMENTS,
    children: [
      {
        path: '',
        component: DocumentsComponent,
      },
      {
        path: 'ticket/:ticketNumber',
        component: DocumentsComponent,
      },
      {
        path: 'incident/:incidentId',
        component: DocumentsComponent,
      },
      {
        path: 'task/:taskId',
        component: DocumentsComponent,
      },
    ],
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.DOCUPLOAD,
    children: [
      {
        path: '',
        component: DocumentsComponent,
      },
      {
        path: 'ticket/:ticketNumber',
        component: DocumentsComponent,
      },
    ],
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.FEEDBACK,
    component: FeedbackTelekomComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: RoutePath.IMPRINT,
    component: ImprintTelekomComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: RoutePath.LOCATION,
    component: MyLocationComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.PRIVACY,
    component: PrivacyTelekomComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: RoutePath.PROFILE_DEFAULT,
    component: ProfileDefaultComponent,
    canActivate: [AuthGuard, MsalGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.PROFILE_JSON,
    component: ProfileDefaultComponent,
    data: { showJson: true },
    canActivate: [MaintenanceGuard],
  },
  {
    path: RoutePath.TASK,
    component: TaskDetailsTelekomComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TASK_VIEW,
    component: TaskDetailsViewComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TASKS,
    component: TaskListTelekomComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKET_CREATE,
    component: TicketCreateTelekomComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKET_CREATE_HELPDESK,
    component: TicketCreateTelekomComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.HELPDESK_MYTICKETS,
    component: TicketListHelpdeskComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.HELPDESK_MYTICKETS_DETAILS,
    component: TicketSummaryHelpdeskComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKET_CREATE_ORDER_ACCESS,
    component: TicketCreateOrderAccessTelekomComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKET_CREATION,
    component: DashboardTicketCreateComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKETS,
    component: TicketListTelekomComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKETSTATUS,
    component: TicketGetStatusDefaultComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKETSTATUS_ITEM,
    component: TicketStatusItemTelekomComponent,
    canActivate: [AuthGuard, MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.LANDING,
    component: RemComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'portfolio', component: PortfolioComponent },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [MaintenanceGuard],
      },
      { path: 'who', component: WhoComponent },
      {
        path: 'status',
        component: StatusComponent,
        canActivate: [MaintenanceGuard],
      },
      {
        path: 'status/:id',
        component: StatusItemComponent,
        canActivate: [MaintenanceGuard],
      },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'imprint', component: ImpressumComponent },
      {
        path: RoutePath.MAINTENANCE,
        component: MaintenanceComponent,
      },
      { path: '*', redirectTo: 'home' },
      { path: '**', redirectTo: 'home' },
    ],
  },
  {
    path: RoutePath.LOGOUT,
    redirectTo: RoutePath.DASHBOARD,
  },
  {
    path: RoutePath.NONE,
    pathMatch: 'full',
    redirectTo: RoutePath.LANDING,
  },
  {
    path: '**',
    redirectTo: RoutePath.LANDING,
  },
  ...defaultRoutes,
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, 
      { bindToComponentInputs: true }
    ), 
    TelekomModule],
  exports: [RouterModule],
})
export class RoutingModule {
  constructor() {
    if (!location.pathname.includes('/authentication/callback')) {
      localStorage.setItem('previousUrl', location.pathname);
    }
  }
}
