import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { Authorization } from '@shared/constants/authorization.constants';
import { protectedResources } from '@core/auth-config';
import { MsalService } from '@azure/msal-angular';

export const AuthGuard = async (route: ActivatedRouteSnapshot) => {
  const msalAuthService = inject(MsalService);

  const tSso = route.queryParamMap.get(Authorization.tsso);

  if (tSso == 'true') {
    const msalInstance: PublicClientApplication =
      msalAuthService.instance as PublicClientApplication;
    msalInstance.clearCache();
    const loginRequest: RedirectRequest = {
      scopes: protectedResources.getIncident.scopes,
    };
    loginRequest.extraQueryParameters = {
      domain_hint: Authorization.telekomAad,
    };
    await msalInstance.initialize();
    await msalInstance
      .handleRedirectPromise()
      .then(async () => {
        await msalInstance.loginRedirect(loginRequest);
      })
      .catch(err => {
        console.log(err);
      });
  }
};
