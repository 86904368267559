import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { UserClient } from '@core/api';
import { b2cPolicies, protectedResources } from '@core/auth-config';
import { Authorization } from '@shared/constants/authorization.constants';
import { rxSubscriptionContainerMixin } from '@shared/utils/rx-subscription-container.mixin';
import { BehaviorSubject, Observable, filter, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends rxSubscriptionContainerMixin() {
  public activeAccountBehavior$ = new BehaviorSubject<AccountInfo>(
    {} as AccountInfo
  );
  activeAccountChange$ = this.activeAccountBehavior$.asObservable();
  public showUser = false;

  public loggedinB2C = false;

  public isGsusUserBehavior$ = new BehaviorSubject<boolean>(false);

  public accountDisplayName = '';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalAuthService: MsalService,
    private userClient: UserClient
  ) {
    super();
  }


  async init() {
    this.showUser = true;
    this.setLoginDisplay();

    this.pushSubscription(
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        tap(() => this.setLoginDisplay()),
        switchMap(() => {
          const accessTokenRequest: SilentRequest = {
            scopes: [...protectedResources.getIncident.scopes],
            account: this.msalAuthService.instance.getAllAccounts()['0'],
          };

          return this.msalAuthService.acquireTokenSilent(accessTokenRequest);
        })
      )
      .subscribe({
        next: (value: AuthenticationResult) => {
          console.log('[UserService] auth via SilentRefresh:', value);
        },
        error: (reason) => {
          console.log('[UserService] Acquire Token silent error: ', reason);
        }
      })
    );

    this.pushSubscription(
      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe({
        next: () => {
          this.setLoginDisplay();
        },
      })
    );
  }


  public OnLogoutClick() {
    this.activeAccountBehavior$.next(null);
    this.msalAuthService.logout();
  }

  setLoginDisplay() {
    const accounts = this.msalAuthService.instance.getAllAccounts();
    if (accounts['0'] != null) {
      this.loggedinB2C = true;
      this.accountDisplayName = `${accounts['0'].name ?? 'login'}`;
      //Checking if user is GSUS
      this.activeAccountBehavior$.next(accounts[0]);
      this.userClient.checkIfGsusUser().subscribe({
        next: isGsusUser => this.isGsusUserBehavior$.next(isGsusUser),
      });
    }
  }

  public OnResetPasswordClick() {
    const editProfileFlowRequest: RedirectRequest = {
      scopes: ['openid'],
      authority: b2cPolicies.authorities.editProfile.authority,
    };
    this.msalAuthService.loginRedirect(editProfileFlowRequest);
  }

  login() {
    let msalInstance: PublicClientApplication = this.msalAuthService.instance as PublicClientApplication;

    if (this.msalGuardConfig.authRequest) {
      const t_sso = this.getParameterByName(Authorization.tsso);

      if (t_sso == 'true') {
        let loginRequest: RedirectRequest = {
          scopes: protectedResources.getIncident.scopes
        };

        loginRequest.extraQueryParameters = { domain_hint: Authorization.telekomAad };

        msalInstance.handleRedirectPromise().then(()=>{
          msalInstance.loginRedirect(loginRequest);
        }).catch(err=>{
          // TODO: Handle errors
          console.log(err);
        });
      } else {
        msalInstance.loginRedirect();
      }
    } else {
      this.msalAuthService.loginPopup();
    }
  }

  getCurrentUserRoles() {
    const currentUser = this.activeAccountBehavior$.getValue();
    if (currentUser?.idTokenClaims) {
      return currentUser.idTokenClaims.roles;
    }
    return undefined;
  }

  checkIfUserHasRole(role: string): Observable<boolean> {
    return this.activeAccountBehavior$.pipe(
      switchMap((account: AccountInfo) => {
        if (account) {
          return of(account.idTokenClaims.roles.includes(role));
        }
        return of(false);
      })
    );
  }

  checkIfUserHasAnyRole(roles: string[]): Observable<boolean> {
    return this.activeAccountBehavior$.pipe(
      switchMap((account: AccountInfo) => {
        if (account) {
          return of(
            roles.some(role => account.idTokenClaims.roles.includes(role))
          );
        }
        return of(false);
      })
    );
  }

  getParameterByName(name: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }
}
