import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

import { SmartPortalProcessError, UploadFile } from '@core/api';
import { FormSubmitService } from '@core/services/form-submit-service/form-submit.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ticket-create-base',
  templateUrl: './ticket-create.base.html',
  styleUrls: ['./ticket-create.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCreateBaseComponent {
  @Input() navigation = true;

  @Input() id: any;

  loading = false;

  card: CardConfig;

  form = new FormGroup({});

  formModel: any = {};

  formFields: FormlyFieldConfig[];

  filledForm: FormlyFieldConfig[] | null;

  extraConfirmFormFields?: FormlyFieldConfig[];

  showExtraConfirmFormFields: boolean;

  title: string;

  description: string;

  descriptionStyle: string;

  descriptionContainerStyle: string;

  subDescription: string;

  subDescriptionStyle: string;

  subDescriptionContainerStyle: string;

  showTicketCreateData: boolean;

  patchValue: Observable<any>;

  options: FormlyFormOptions = {
    formState: {
      mainModel: this.formModel,
    },
  };

  successMessage: string;

  protected prepareSubmit: (data: any) => any;

  protected clientEndpoint: (data: any) => Observable<any>;

  protected router: Router;

  public cd: ChangeDetectorRef;

  protected el: ElementRef;

  private dialog: MatDialog;

  protected translateService: TranslateService;

  protected isSubmitted: boolean;

  protected formSubmitService: FormSubmitService;

  protected msalService: MsalService;

  constructor(@Inject(Injector) injector: Injector) {
    this.router = injector.get(Router);
    this.cd = injector.get(ChangeDetectorRef);
    this.el = injector.get(ElementRef);
    this.dialog = injector.get(MatDialog);

    this.translateService = injector.get(TranslateService);

    this.formSubmitService = injector.get(FormSubmitService);

    this.formSubmitService.isSubmitted.subscribe(res => {
      this.isSubmitted = res;
    });

    this.msalService = injector.get(MsalService);

    this.successMessage = this.translateService.instant(
      'ticket.create.success.message'
    );
  }

  validateForm() {
    for (const control in this.form.controls) {
      if (this.form.controls[control].invalid) {
        console.log('invalid');
      }
    }
    if (this.form.valid) {
      this.showFormSummary();
    } else {
      this.scrollToFirstInvalidControl(true);
    }
  }

  protected async scrollToFirstInvalidControl(repeatScroll: boolean) {
    this.form.markAllAsTouched();
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    try {
      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
        firstInvalidControl.focus();
      }
    } catch (e) {
      console.error('[ticket-create] scrollToFirstInvalidControl:', e);
    } finally {
      if (repeatScroll) {
        setTimeout(() => {
          this.scrollToFirstInvalidControl(false);
        }, 10);
      }
    }
  }

  async showFormSummary() {
    this.filledForm = this.formFields;
    this.cd.detectChanges();
  }

  fileRead(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result as string);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  discardSumUpForm() {
    this.filledForm = null;
  }

  async submit() {
    this.loading = true;

    const files = this.form.get('attachments') as unknown as FormArray;

    const packedFiles: Array<UploadFile> = [];
    if (files !== null) {
      for await (const [_index, f] of files.controls.entries()) {
        const cont = (await this.fileRead(f.value.attachment)).split(',')[1];
        packedFiles.push({
          contentBase64Encoded: cont,
          metadata: {
            isInternal: false,
            documentId: '',
            name: f.value.attachment.name,
            guidExternalSystem: undefined,
            crmGuidOriginRecord: undefined,
            filename: f.value.attachment.name,
            dynamicsUri: undefined,
            publishDocument: undefined,
            statusCode: 'Aktiv',
            contactId: undefined,
            rentalContractId: undefined,
            functionalLocationId: undefined,
            accountId: undefined,
            documentTypeId: f.value.attachmentType,
            changeDate: undefined,
          },
        });
      }
    }

    const preparedData = this.prepareSubmit(this.form.value);

    this.clientEndpoint({
      ...preparedData,
      files: packedFiles,
    }).subscribe({
      next: () => {
        console.log('client endpoint next');
        this.form.reset();
        this.loading = false;
        const successDialog = this.dialog.open(DialogComponent, {
          panelClass: 'smartportal-dialog',
          width: '500px',
          data: {
            type: 'okButton',
            title: this.translateService.instant('ticket.create.success.title'),
            message: this.successMessage,
            okButtonText: this.translateService.instant(
              'ticket.create.success.button__text'
            ),
          },
        });

        successDialog.afterClosed().subscribe({
          next: () => {
            const targetUrl = this.getStatusPath();
            this.router.navigate([targetUrl]);
          },
        });
        this.cd.detectChanges();
      },
      error: error => {
        let errTitle = this.translateService.instant(
          'ticket.create.error.title'
        );
        let errMessage = this.translateService.instant(
          'ticket.create.error.message'
        );
        if (
          error.errorCode == SmartPortalProcessError.INCIDENT_ALREADY_EXISTS
        ) {
          errTitle = this.translateService.instant(
            'ticket.create.error.duplicateIncident.title'
          );
          errMessage = this.translateService.instant(
            'ticket.create.error.duplicateIncident.message'
          );
        }
        this.loading = false;
        this.dialog.open(DialogComponent, {
          width: '300px',
          data: {
            type: 'noButtons',
            title: errTitle,
            message: errMessage,
          },
        });
        console.error(error);
        this.formSubmitService.isSubmitted.emit(false);
        this.loading = false;
        this.cd.detectChanges();
      },
    });
  }

  getStatusPath() {
    const parts = this.router.url.split('/'); //  ['', 'ticket', 'any']
    const modulePath = parts[1];
    let newRoute = '';
    if (parts.length > 2) {
      newRoute = `/${modulePath}/status`;
    } else {
      newRoute = '/ticket/status';
    }
    return newRoute;
  }
}
