import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { UserService } from '@core/services/oauth-service/user.service';

@Component({
  selector: 'app-dashboard-ticket-create',
  templateUrl: '../shared/dashboard.component.html',
  styleUrls: ['../shared/dashboard.component.scss'],
})
export class DashboardTicketCreateComponent {
  userInfo = undefined;
  viewTitle = 'Ticketerstellung';
  linksTitle = '';
  links: Array<any> = [];
  linksAnonymous: Array<any> = [];
  cardsTitle = 'Ihre Meldungen';
  cards: Array<any> = [];

  itemsO = [
    {
      id: 'optionalServices',
      label: 'Optionale Leistungen',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
    {
      id: 'changeBasisService',
      label: 'Änderung Basisleistung',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
  ];

  itemsR = [
    {
      id: 'building',
      label: 'Baulast',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
    {
      id: 'purchase',
      label: 'Kaufanfrage',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
    {
      id: 'easement',
      label: 'Dienstbarkeit',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
    {
      id: 'rentSpaceExternally',
      label: 'Fläche extern vermieten',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
    {
      id: ' ',
      label: '.....',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
    {
      id: ' ',
      label: '',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
    {
      id: ' ',
      label: '',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
    {
      id: ' ',
      label: '',
      onClick: () => {
        this.router.navigateByUrl('create');
      },
    },
  ];

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public userService: UserService
  ) {
    this.breadcrumbService.setBreadcrumbs([{ label: this.viewTitle }]);

    const section = [];

    section.push(
      {
        label: 'Optionale Leistungen',
        items: this.itemsO,
      },
      {
        label: 'Mietvertragsmanagement',
        items: this.itemsR,
      }
    );
    this.links = section;
  }
}
