<su-card [card]="cardConfigurationWithoutFooter">
  <div card-content>
    <form [formGroup]="form">
      <formly-form
        [form]="form"
        [fields]="formFields"
        [model]="formModel"></formly-form>
    </form>
    <app-spinner-overlay [show]="isLoading" [label]="'location.myLocation.location_notification.loading_message' | translate"></app-spinner-overlay>
  </div>
</su-card>
<div class="mail-container">
  <su-card [card]="cardNotification">
    <div card-content>
    <app-html-editor
    [(content)]="htmlContent"
    [config]="angularEditorConfig"></app-html-editor>
  </div>
  </su-card>

  <su-card [card]="cardConfigurationWithoutFooter">
    <div card-content >
    <app-telekom-email-template
    [address]="location?.dtArchObjAddress[0]?.address"
    [emailContent]="htmlContent"
    [subject]="subject"></app-telekom-email-template>
  </div>
  </su-card>
  </div>
