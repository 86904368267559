<su-headline h="h1" skin="primary">{{config.title | translate}}</su-headline>

<div>
  <ng-container>
    <mtx-grid
      #grid
      [columns]="columns"
      [data]="data.items"
      [length]="data.totalCount"
      [pageOnFront]="false"
      [sortOnFront]="config.grid.filter.sortOnFront"
      [loading]="isLoading"
      [rowStriped]="true"
      [noResultText]="'grid.no_results' | translate"
      (rowClick)="clickRowWrapper($event)"
      [rowHover]="true"
      [cellSelectable]="false"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      (sortChange)="sortChange($event)"
      (page)="getNextPage($event)"
      [pageSize]="config.grid.paging.pageSize"
      [pageIndex]="config.grid.paging.pageIndex"
      [showPaginator]="config.grid.paging.showPaginator"
      [columnMenuButtonText]="'grid.columns_shown' | translate"
      [columnMenuHeaderText]="'grid.columnMenuHeaderText' | translate"
      [columnHideable]="true"
      [columnHideableChecked]="'show'"
      [showColumnMenuFooter]="true"
      [columnMenuFooterTemplate]="menuFooter"
      [showToolbar]="config.grid.showToolbar"
      [showColumnMenuHeader]="true"
      [toolbarTemplate]="toolbarTpl"
      [showSidebar]="true"
      [columnResizable]="config.grid.columnResizable"
      [expandable]="fieldsInExpansionTemplate.length > 0"
      [expansionTemplate]="expansionTemplate"
      [rowSelectable]="config.grid.select.rowSelectable"
      [multiSelectable]="config.grid.select.multiSelectable"
      [headerExtraTemplate]="headerTemplate"
      [(rowSelected)]="selectedRows"
      [rowSelectionFormatter]="rowSelectionFormatter"
      [hideRowSelectionCheckbox]="false"
      [showColumnMenuButton]="false">
      <ng-template #visibilityTemplate let-row>
        <div class="visibility-container" *ngIf="canEditVisibility">
          <mat-select
            *ngIf="row.visibilityEditable"
            [(ngModel)]="row.visibilityId"
            class="select"
            (ngModelChange)="onSelectChange(row)"
            [placeholder]="'Bitte auswählen'">
            <mat-option
              *ngFor="let visibility of documentVisibilities"
              [value]="$any(visibility).value"
              [title]="$any(visibility).label"
              [matTooltip]="$any(visibility).tooltip">
              {{ $any(visibility).label }}
            </mat-option>
          </mat-select>
          <div *ngIf="!row.visibilityEditable" class="select">
            {{getVisibilityLabel(row.visibilityId)}}
          </div>
          <su-button
            [hidden]="row.visibilityEditable"
            icon="edit"
            skin="secondary"
            (onClick)="editVisibility(row)"></su-button>
          <su-button
            [hidden]="!row.visibilityEditable"
            icon="close"
            skin="secondary"
            (onClick)="setPreviousVisibility(row)"></su-button>
          <su-button
            icon="save"
            skin="primary"
            [disabled]="!row.isOptionChanged || !row.visibilityEditable || row.visibilityId === row.previousVisibilityId"
            (onClick)="saveVisibility(row)"></su-button>
        </div>
        <div *ngIf="!canEditVisibility" class="select">
          {{getVisibilityLabel(row.visibilityId)}}
        </div>
      </ng-template>
    </mtx-grid>
  </ng-container>
</div>

<ng-template #menuFooter> </ng-template>

<ng-template #toolbarTpl> </ng-template>

<ng-template #expansionTemplate let-row>
  <div>
    <p *ngFor="let field of fieldsInExpansionTemplate">{{row[field]}}</p>
  </div>
</ng-template>

<ng-template
  #bufferedActionsTemplate
  let-row
  let-index="index"
  let-col="colDef">
  <su-button
    *ngIf="row.status === 1 && removeBufferredDocument"
    icon="remove"
    skin="primary"
    (onClick)="removeBufferredDocument(row)"
    [title]="'documents.tooltip__remove' | translate">
    <mat-icon>close</mat-icon>
  </su-button>
</ng-template>

<ng-template
  #documentActionsTemplate
  let-row
  let-index="index"
  let-col="colDef">
  <div class="button-container">
    <su-button
      *ngIf="archiveToImageMasterAllowed() && !row.imageMaster"
      skin="secondary"
      icon="share"
      (onClick)="archiveFileToImageMaster($event, row, grid)"
      [title]="'documents.tooltip__moveToImageMaster' | translate"
      [disabled]="row.actionsDisabled || hideRowActions(row)">
      <!--[loading]="row.archiveToImageMasterLoading" -->
    </su-button>
    <su-button
      *ngIf="downloadDocumentAllowed() && !row.imageMaster"
      skin="secondary"
      icon="download"
      (onClick)="downloadDocument($event, row)"
      [title]="'documents.tooltip__download' | translate"
      [disabled]="row.actionsDisabled || hideRowActions(row)">
      <!-- [loading]="row.downloadLoading" -->
    </su-button>
  </div>
</ng-template>

<ng-template #documentDeleteTemplate let-row let-index="index" let-col="colDef">
  <su-button
    *ngIf="row.canDelete"
    skin="secondary"
    icon="trash"
    (onClick)="deleteDocument(row, grid)"
    [title]="'documents.tooltip__delete' | translate">
  </su-button>
</ng-template>

<ng-template #headerTemplate let-col="colDef">
  <div *ngIf="this.hasMultiDownload && col.field === 'action'">
    <su-button
      *ngIf="!downloadingDocuments"
      icon="file-zipper"
      [label]="'(' + selectedRows.length.toString() + ')'"
      [disabled]="selectedRows.length < 2"
      (onClick)="downloadDocuments()">
      ({{selectedRows.length}})
    </su-button>
    <div *ngIf="downloadingDocuments">
      <mat-spinner [diameter]="20"></mat-spinner>
    </div>
  </div>
</ng-template>
