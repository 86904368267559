<ng-container *ngIf="refreshComponent">
  <mat-form-field data-debug="formly-preview-autocomplete-type" class="custom">
    <mat-label>{{ props.label }}</mat-label>
    <mtx-select
      #select
      [bindLabel]="typeAheadProperty"
      [clearable]="false"
      [editableSearchTerm]="true"
      [formControl]="formControl"
      [items]="filteredList$ | async"
      [loading]="isLoading"
      [loadingText]="'shared.loading_data' | translate"
      [notFoundText]="'grid.no_results' | translate"
      [typeToSearchText]="'form.type_to_search' | translate"
      [typeahead]="searchTypeAhead$"
      (change)="setValue($event); select.blur()"
      >
      <ng-template ng-header-tmp let-index="index">
        <div class="su-autocomplete_row">
          <div
            *ngFor="let definition of columnDefinitions"
            [class]="definition.classes">
            {{ definition.labelTranslationKey | translate }}
          </div>
        </div>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm">
        <div class="su-autocomplete_row">
          <div
            *ngFor="let definition of columnDefinitions"
            [class]="definition.classes">
            <span *ngIf="!definition.type || (definition.type && definition.type !== 'icon')">
              {{ getOptionLabelFormatted(item, definition) }}
            </span>
            <span *ngIf="definition.type === 'icon'" class="icon">
              <mat-icon>
                {{ getOptionLabelFormatted(item,definition) }}
              </mat-icon>
            </span>
          </div>
        </div>
      </ng-template>
    </mtx-select>
    <mat-error *ngIf="formControl?.hasError('required')">
      <span [innerText]="field.validation?.messages?.required"></span>
    </mat-error>
  </mat-form-field>
  
  <div class="preview">
    <div *ngFor="let definition of columnDefinitions">
      <ng-container *ngIf="!definition.hideInPreview">
        <div class="info-card_field">
          <div class="label">
            {{ definition.labelTranslationKey | translate }}
          </div>
          <div>
            {{ getOptionLabelFormatted(selectedResult, definition) }}
          </div>
        </div>
      </ng-container>
    </div>
    <su-button
      skin="secondary"
      *ngIf="selectedResult"
      (onClick)="deleteSelected()"
      icon="trash">
    </su-button>
  </div>
</ng-container>

<ng-container *ngIf="!refreshComponent">
  <mat-form-field data-debug="formly-preview-autocomplete-type" class="custom">
    <mat-label>{{ props.label }}</mat-label>
  <mtx-select
#select
[bindLabel]="typeAheadProperty"
[clearable]="false"
[editableSearchTerm]="true"
[formControl]="formControl"
[items]="filteredList$ | async"
    [loading]="isLoading"
  [loadingText]="'shared.loading_data' | translate"
    [notFoundText]="'grid.no_results' | translate"
  [typeToSearchText]="'form.type_to_search' | translate"
[typeahead]="searchTypeAhead$"
    (change)="setValue($event); select.blur()"
  >
<ng-template ng-header-tmp let-index="index">
  <div class="su-autocomplete_row">
    <div
  *ngFor="let definition of columnDefinitions"
[class]="definition.classes">
          {{ definition.labelTranslationKey | translate }}
        </div>
      </div>
    </ng-template>
  <ng-template
ng-option-tmp
      let-item="item"
    let-index="index"
  let-search="searchTerm">
<div class="su-autocomplete_row">
  <div
*ngFor="let definition of columnDefinitions"
[class]="definition.classes">
<span *ngIf="!definition.type || (definition.type && definition.type !== 'icon')">
  {{ getOptionLabelFormatted(item, definition) }}
</span>
<span *ngIf="definition.type === 'icon'" class="icon">
  <mat-icon>
    {{ getOptionLabelFormatted(item,definition) }}
  </mat-icon>
</span>
</div>
</div>
</ng-template>
  </mtx-select>
<mat-error *ngIf="formControl?.hasError('required')">
  <span [innerText]="field.validation?.messages?.required"></span>
</mat-error>
</mat-form-field>

<div class="preview">
  <div *ngFor="let definition of columnDefinitions">
    <ng-container *ngIf="!definition.hideInPreview">
      <div class="info-card_field">
        <div class="label">
          {{ definition.labelTranslationKey | translate }}
        </div>
      <div>
        {{ getOptionLabelFormatted(selectedResult, definition) }}
      </div>
    </div>
  </ng-container>
</div>
<su-button
skin="secondary"
*ngIf="selectedResult"
(onClick)="deleteSelected()"
icon="trash">
</su-button>
</div>
</ng-container>
