import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {
  AuthClient,
  BaseSearchOptions,
  FileClient,
  IncidentViewOptions,
  OrderDirection,
  SmartPortalProcessError,
  UserClient,
} from '@core/api';
import { rxSubscriptionContainerMixin } from '@core/mixins/rx-subscription-container.mixin';
import { UserService } from '@core/services/oauth-service/user.service';
import { TableService } from '@core/services/table-service/table.service';
import {
  MtxGridColumn,
  MtxGridRowSelectionFormatter,
} from '@ng-matero/extensions/grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { TableFilterComponent } from '../table-filter/table-filter.component';
import {
  // AddButtonType,
  IAddButtonConfig,
  ITableConfig,
  ITableData,
  ITableFilterConfig,
  ITableGridConfig,
  ITablePagingConfig,
  ITableSelectConfig,
} from './table-base.types';

@Component({
  selector: 'app-list-base',
  templateUrl: './table-base.html',
  styleUrls: ['./table-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBaseComponent
  extends rxSubscriptionContainerMixin()
  implements OnDestroy, AfterViewInit
{
  config: ITableConfig = <ITableConfig>{
    title: '',
    translatePath: '',
    addButton: <IAddButtonConfig>{
      disabled: true,
    },
    grid: <ITableGridConfig>{
      showToolbar: true,
      columnResizable: true,
      paging: <ITablePagingConfig>{
        pageIndex: 0,
        pageSize: 50,
        showPaginator: true,
      },
      filter: <ITableFilterConfig>{
        filterVisible: false,
        sortOnFront: false,
        filterOptions: {},
      },
      select: <ITableSelectConfig>{
        rowSelectable: false,
        multiSelectable: false,
      },
    },
  };

  data = <ITableData>{
    items: undefined,
    itemsOriginal: undefined,
    totalCount: 0,
  };

  isLoading = false;

  downloadingDocuments = false;

  showDocumentDeleteButton = false;

  columns: Array<MtxGridColumn> = [];

  filterFields?: FormlyFieldConfig[];

  fieldsInExpansionTemplate: string[] = [];

  viewOptionsFields?: FormlyFieldConfig[];

  query: BaseSearchOptions = {
    pagingOptions: {
      skip:
        this.config.grid.paging.pageIndex * this.config.grid.paging.pageSize,
      take: this.config.grid.paging.pageSize,
    },
  };

  @ViewChild(TableFilterComponent) crudFilterComponent: TableFilterComponent;

  getTableData$: (
    query?: BaseSearchOptions | undefined,
    filterOptions?: any,
    viewOptions?: IncidentViewOptions | undefined
  ) => Observable<any>;

  clickRow: (data: any, router: Router, route: ActivatedRoute) => void;

  clickAdd: (router: Router, route: ActivatedRoute) => void;

  public router: Router;

  protected route: ActivatedRoute;

  protected toastr: ToastrService;

  protected cd: ChangeDetectorRef;

  protected tableService: TableService;

  protected translateService: TranslateService;

  protected msalService: MsalService;

  protected fileClient: FileClient;

  protected userClient: UserClient;

  protected userService: UserService;

  protected authClient: AuthClient;

  protected sysUser: boolean;

  protected selectedRows: any[] = [];

  documentVisibilities = [];

  canEditVisibility = false;

  protected userEmail;
  
  rowSelectionFormatter: MtxGridRowSelectionFormatter =
    this.setRowSelectionFormatter();

  constructor(@Inject(Injector) injector: Injector) {
    super();

    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.toastr = injector.get(ToastrService);
    this.cd = injector.get(ChangeDetectorRef);
    this.tableService = injector.get(TableService);
    this.translateService = injector.get(TranslateService);
    this.msalService = injector.get(MsalService);
    this.fileClient = injector.get(FileClient);
    this.userClient = injector.get(UserClient);
    this.userService = injector.get(UserService);
    this.authClient = injector.get(AuthClient);

    this.userEmail =
      this.msalService.instance.getActiveAccount().idTokenClaims.email;

    this.canEditVisibility = this.msalService.instance
      .getActiveAccount()
      .idTokenClaims.roles.includes(
        UserRoles.SMARTPORTAL_DOCUMENT_SETVISIBILITY_GLOBAL
      );

    this.userService.isGsusUserBehavior$.subscribe({
      next: isGsus => {
        this.sysUser = isGsus;
      }
    });
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe({
      next: params => {
        if (params.filterOptions) {
          this.config.grid.filter.filterOptions = {
            ...this.config.grid.filter.filterOptions,
            ...JSON.parse(params.filterOptions), // todo: oder ohne JSON.parse
          };
          // todo: check this
          //this.crudFilterComponent.patchValue(this.config.grid.filter.filterOptions);
          this.config.grid.filter.filterVisible = true;
        }

        // todo: and this :)
        /*
               if (params['filterOptions']) {
          this.config.grid.filter.filterOptions = {
            ...this.config.grid.filter.filterOptions,
            ...JSON.parse(params['filterOptions']), // todo: oder ohne JSON.parse
          };
          // todo: check this
          //this.crudFilterComponent.patchValue(this.config.grid.filter.filterOptions);
          this.config.grid.filter.filterVisible = true;
        }
         */
        if (params) {
          this.config.grid.filter.filterOptions = {
            ...this.config.grid.filter.filterOptions,
            ...params,
          };
          // todo: check this
          //this.crudFilterComponent.patchValue(this.config.grid.filter.filterOptions);
          this.config.grid.filter.filterVisible = true;
        }

        this.getData();
      },
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  hasMultiDownload = false;

  // eslint-disable-next-line
  async downloadDocuments() {}

  downloadDocument(_event, row) {
    console.log('row', row);
  }

  deleteDocument(row, _grid) {
    console.log('row', row);
  }

  archiveFileToImageMaster(_event, row, _grid) {
    console.log('row2', row);
  }

  getData() {
    this.isLoading = true;

    this.pushSubscription(
      this.getTableData$(
        this.query,
        this.config.grid.filter.filterOptions,
        this.config.grid.filter.viewOptions
      ).subscribe({
        next: data => {
          this.data.items = data.entites;
          this.data.totalCount = data.totalCount;
          this.isLoading = false;
          this.cd.detectChanges();

          if (this.config.grid.filter.filterOnFront) {
            this.refreshFromFilter();
          }
        },
        error: error => {
          console.log(error);
          this.isLoading = false;
          if (
            error.errorCode ===
            SmartPortalProcessError.USER_DOES_NOT_EXIST_IN_DATABASE
          ) {
            // User does not exist in database
            this.toastr.info(
              'Hier können Sie zukünftig alle Tickets/Aufgaben sehen, die dem Dienstleister zugewiesen sind.',
              this.translateService.instant('alert.info')
            );
          } else {
            this.toastr.error(
              this.translateService.instant(
                'crud.details.error.failed_to_load_msg'
              ),
              this.translateService.instant(
                'crud.details.error.failed_to_load_title'
              ),
              { timeOut: 0 }
            );
          }
          this.isLoading = false;
          this.cd.detectChanges();
        },
      })
    );
  }

  refresh() {
    this.getData();
  }

  refreshFromFilter() {
    if (!this.config.grid.filter.filterOnFront) {
      this.refresh();
    } else {
      if (!this.data.itemsOriginal) {
        this.data.itemsOriginal = Object.assign([], this.data.items);
      }
      this.data.items = Object.assign([], this.data.itemsOriginal);
      this.data.items = this.data.items.filter((row: any) => {
        let found = false;
        let hasFilter = false;
        Object.keys(this.config.grid.filter.filterOptions).forEach(
          filterKey => {
            const filterText =
              this.config.grid.filter.filterOptions[filterKey]?.toLowerCase();

            if (!found) {
              found = this.isFilterFound(row, filterKey, filterText);
            }

            if (filterText) {
              hasFilter = true;
            }
          }
        );
        return !hasFilter || found ? row : null;
      });

      this.cd.detectChanges();
    }
  }

  updateFilter(filterOptions: any) {
    this.config.grid.filter.filterOptions = filterOptions;
  }

  updateViewOptions(viewOptions: any) {
    this.config.grid.filter.viewOptions = viewOptions.viewOption;
    this.getData();
  }

  resetFilter() {
    this.config.grid.filter.filterOptions = {};
    this.refresh();
  }

  toggleFilter() {
    this.config.grid.filter.filterVisible =
      !this.config.grid.filter.filterVisible;
  }

  sortChange(event) {
    this.query = {
      ...this.query,
      orderOptions: {
        attributeName: event.active,
        orderDirection: this.directionStringToEnum(event.direction),
      },
    };
    if (!this.config.grid.filter.sortOnFront) {
      this.getData();
    }
  }

  getNextPage(e: PageEvent) {
    this.config.grid.paging.pageIndex = e.pageIndex;
    this.config.grid.paging.pageSize = e.pageSize;

    this.query = {
      ...this.query,
      pagingOptions: {
        skip:
          this.config.grid.paging.pageIndex * this.config.grid.paging.pageSize,
        take: this.config.grid.paging.pageSize,
      },
    };

    this.getData();
  }

  clickRowWrapper(row) {
    this.clickRow(row, this.router, this.route);
  }

  clickAddWrapper() {
    this.clickAdd(this.router, this.route);
  }

  downloadDocumentAllowed(): boolean {
    return this.hasRolePrefix(UserRoles.SMARTPORTAL_DOCUMENT_READ_PREFIX);
  }

  archiveToImageMasterAllowed(): boolean {
    return this.hasRolePrefix('smartportal.document.imaupload.global');
    // return this.hasRolePrefix(UserRoles.SMARTPORTAL_DOCUMENT_IMAUPLOAD_PREFIX);
  }

  hasRolePrefix(roleNamePrefix: string): boolean {
    const userRoles =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles;
    return !!userRoles?.find(role => role.startsWith(roleNamePrefix));
  }

  hideRowActions(row: any): boolean {
    if (this.selectedRows?.length > 1) {
      return !this.selectedRows.find(selectedRow => {
        return selectedRow.documentId === row.documentId;
      });
    }
    return false;
  }

  protected configure(config: ITableConfig) {
    return { ...this.config, ...config };
  }

  protected translate(key) {
    return this.translateService.instant(`${this.config.translatePath}.${key}`);
  }

  // protected initAddButton(type: AddButtonType) {
  //   this.pushSubscription(
  //     this.tableService.initAddButton$(type).subscribe({
  //       next: (config: IAddButtonConfig) => {
  //       this.config.addButton.label = config.label;
  //       this.config.addButton.disabled = config.disabled;
  //     }})
  //   );
  // }

  protected isFilterFound(
    row: any,
    filterKey: string,
    filterText: string
  ): boolean {
    let found = false;
    if (filterText) {
      const text = row[filterKey]?.toString().toLowerCase();
      if (text.includes(filterText)) {
        found = true;
      }
    }
    return found;
  }

  protected removeBufferredDocument(row: any) {
    // implemented in detail-file-list-buffered
    console.log(row);
  }

  private directionStringToEnum(dir: string): OrderDirection {
    switch (dir) {
      case 'asc': {
        return OrderDirection.Asc;
      }
      case 'desc': {
        return OrderDirection.Desc;
      }
      default: {
        return OrderDirection.None;
      }
    }
  }

  protected getVisibilityLabel(visibilityId: string) {
    console.log(visibilityId);
  }

  // remember visibility state of columns for refresh
  saveColumnVisibility() {
    this.columns.forEach(x => {
      x.hide = !x.show;
    });
  }

  onSelectChange(row: any) {
    row.isOptionChanged = true;
  }

  saveVisibility(row: any) {
    const request = {
      documentId: row.documentId,
      visibilityId: row.visibilityId,
    };
    this.fileClient.setFileVisibility(request).subscribe({
      next: next => {
        console.log(next);
        row.visibilityEditable = false;
        this.toastr.success(
          this.translateService.instant(
            'documents.documentVisibilities.update_success.message'
          )
        );
        this.cd.detectChanges();
      },
      error: err => {
        console.log(err);
        this.toastr.error(
          this.translateService.instant(
            'documents.documentVisibilities.update_error.message'
          ),
          this.translateService.instant(
            'documents.documentVisibilities.update_error.title'
          )
        );
      },
    });
  }

  editVisibility(row) {
    row.visibilityEditable = !row.visibilityEditable;
    row.previousVisibilityId = row.visibilityId;
  }

  setPreviousVisibility(row) {
    row.visibilityEditable = !row.visibilityEditable;
    row.visibilityId = row.previousVisibilityId;
  }

  canDelete(_row, _user) {}

  setRowSelectionFormatter() {
    return (this.rowSelectionFormatter = {
      disabled: (rowData: any, _index: number) => {
        return rowData.imageMaster;
      },
      hideCheckbox: (rowData: any, _index: number) => {
        return rowData.imageMaster;
      },
    });
  }
}
