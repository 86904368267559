import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ContactSearchFormlyFieldService } from '@core/services/contact-search-formly-field-service/contact-search-formly-field.service';

export function editor(
  translateService: TranslateService,
  contactFormlyFieldService: ContactSearchFormlyFieldService
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.label__processor'
            ),
          },
        },
        {
          key: 'editor',
          fieldGroup: [
            ...contactFormlyFieldService.autocompleteSearch(
              'ticket.create.placeholder__search_processor',
              false,
              true
            ),
          ],
        },
      ],
    },
  ];
}
