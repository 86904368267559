<!-- <img [src]="iconUrl" alt="dummy-icon">-->
<div class="fact-container">
  <div class="fact-vertical">
    <app-animated-counter
      [counterNum]="getNumberFromString(factAmount)"
      [duration]="1000"></app-animated-counter>
  </div>
  <div class="fact-vertical" *ngIf="factType !== ''">&nbsp;Mio. qm</div>
  <!-- <p class="fact-type" *ngIf="factType != ''"></p> -->
</div>
<div class="subtitle">{{ subtitle }}</div>
