import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RegexpEmail } from '@shared/utils/regex';
import {
  phoneConstraints,
  phoneValidationMessages,
} from '../ticket-created-shared.field-configs';

export function ordererContact(
  translateService: TranslateService
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__contact_orderer'
            ),
          },
        },
        {
          key: 'ordererContact',
          type: 'flex-layout',
          fieldGroup: [
            {
              type: 'input',
              key: 'firstname',
              props: {
                label: translateService.instant(
                  'ticket.create.label__firstName'
                ),
                visibleInConfirm: true,
                maxLength: 50,
                required: true,
              },
            },
            {
              type: 'input',
              key: 'lastname',
              props: {
                label: translateService.instant(
                  'ticket.create.label__lastName'
                ),
                maxLength: 50,
                visibleInConfirm: true,
                required: true,
              },
            },
            {
              type: 'input',
              key: 'phonenumber',
              defaultValue: '0049',
              wrappers: ['tooltip', 'form-field'],
              props: {
                label: translateService.instant(
                  'ticket.create.label__phone'
                ),
                visibleInConfirm: true,
                required: true,
                ...phoneConstraints,
                tooltip: translateService.instant(
                  'ticket.create.tooltip__phone'
                ),
              },
              validation: phoneValidationMessages(translateService),
            },
            {  
              type: 'input',
              key: 'email',
              props: {
                label: translateService.instant(
                  'ticket.create.label__email'
                ),
                pattern: RegexpEmail,
                visibleInConfirm: true,
                required: true,
                maxLength: 100,
              },
              validation: {
                messages: {
                  pattern: () =>
                    translateService.instant('formErrors.email.pattern'),
                },
              },
            },
            {
              type: 'input',
              key: 'corporation',
              props: {
                label: translateService.instant(
                  'ticket.create.label__corporation'
                ),
                visibleInConfirm: true,
                required: true,
                maxLength: 100,
              },
            },
            {
              type: 'input',
              key: 'operation',
              props: {
                label: translateService.instant(
                  'ticket.create.label__operation'
                ),
                visibleInConfirm: true,
                required: true,
                maxLength: 100,
              },
            },
          ],
        },
      ],
    },
  ];
}
