import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Tab } from '@affinis/smartus-components/lib/tabs/tabs.components.types';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BuildingFaultIncidentViewModel, FmDocument, IncidentClient, SmartPortalProcessError } from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { FileService } from '@core/services/file-service/file.service';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { TranslateService } from '@ngx-translate/core';
import { DocumentVisibility } from '@shared/types/document.types';
import { rxSubscriptionContainerMixin } from '@shared/utils/rx-subscription-container.mixin';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

interface DocumentRow extends FmDocument {
  downloadLoading: boolean;
  archiveToImageMasterLoading: boolean;
  actionsDisabled: boolean;
}

@Component({
  selector: 'app-ticket-summary-helpdesk',
  templateUrl: './ticket-summary-helpdesk.component.html',
  styleUrls: ['./ticket-summary-helpdesk.component.scss'],
})
export class TicketSummaryHelpdeskComponent
  extends rxSubscriptionContainerMixin() 
  implements OnInit
{
  @Input() incidentId = '';

  @ViewChild('documentActionsTemplate', { static: true })
  documentActionsTemplateRef!: TemplateRef<void>;

  ticketData$: Observable<BuildingFaultIncidentViewModel>;

  title = this.translateService.instant('ticket.create.helpdesk.summary');

  cardSummary: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  cardDocuments: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };
  
  tabs: Tab[] = [
    {
      label: this.translateService.instant('ticket.create.label__confirmHeadline'),
      id: 'summary',
      onClick: (id: string) =>  this.onTabClick(id),
    },
    {
      label: this.translateService.instant('documents.title'),
      id: 'documents',
      onClick: (id: string) =>  this.onTabClick(id),
    },
  ];

  activeTab = this.tabs[0].id;

  documentVisibilities = [
    {
      value: DocumentVisibility.Intern,
      label: this.translateService.instant('documents.documentVisibilities.0'),
      tooltip: this.translateService.instant(
        'documents.documentVisibilities.tooltip.0'
      ),
    },
    {
      value: DocumentVisibility.InternAndContractors,
      label: this.translateService.instant('documents.documentVisibilities.1'),
      tooltip: this.translateService.instant(
        'documents.documentVisibilities.tooltip.1'
      ),
    },
    {
      value: DocumentVisibility.Public,
      label: this.translateService.instant('documents.documentVisibilities.2'),
      tooltip: this.translateService.instant(
        'documents.documentVisibilities.tooltip.2'
      ),
    },
  ];

  columns: MtxGridColumn[] = [];

  constructor(
    public incidentClient: IncidentClient,
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private fileService: FileService
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.ticketData$ = this.incidentClient.getBuildingFaultIncident(this.incidentId);
    this.ticketData$.subscribe({
      next: data => {
        this.tabs[1].disabled = data.documents.length == 0;
      }
    });
    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.translateService.instant('ticket.create.helpdesk.my_tickets'),
        onClick: () => {
          this.router.navigateByUrl('/helpdesk/my-tickets');
        },
      },
      {
        label: this.title,
      },
    ]);

    this.columns = [
      {
        header: this.translateService.instant('documents.label__title'),
        field: 'filename',
        sortable: true,
      },
      {
        header: this.translateService.instant('documents.label__documentVisibility'),
        field: 'visibilityId',
        width: '15%',
        sortable: true,
        formatter: (data: any) =>
          this.getVisibilityLabel(data.visibilityId)
      },
      {
        header: this.translateService.instant('documents.label__created'),
        field: 'changeDate',
        width: '15%',
        sortable: true,
        formatter: (data: FmDocument) =>
          data.changeDate
            ? moment.utc(data.changeDate).local().format('DD.MM.YYYY, HH:mm:ss')
            : '--',
      },
      {
        header: '',
        width: '120px',
        field: 'action',
        cellTemplate: this.documentActionsTemplateRef,
      },
    ];
  }

  onTabClick(id: string) {
    this.activeTab = id;
  }

  getVisibilityLabel(visibilityId: string) {
    const found = this.documentVisibilities?.find(
      (option: any) => option.value === visibilityId
    );
    return found?.label ? found.label : '--';
  }

  async downloadDocument(event: Event, row: DocumentRow) {
    if (!row.blobFilename) {
      this.toastr.error(
        this.translateService.instant('detailFields.fileDownload.warn')
      );
      return;
    }

    event.stopPropagation();
    row.actionsDisabled = true;
    row.downloadLoading = true;
    this.cd.detectChanges();

    this.pushSubscription(
      this.fileService
        .downloadFile(row.blobFilename, row.contentType)
        .subscribe({
          next: res => {
            const fileResponse = res;
            row.actionsDisabled = false;
            row.downloadLoading = false;
            this.cd.detectChanges();

            if (fileResponse !== null) {
              this.fileService.saveFile(fileResponse, row.name);
            }
          },
          error: error => {
            if (
              error.errorCode ===
              SmartPortalProcessError.FILESIZE_DOWNLOAD_LIMIT
            ) {
              this.toastr.error(
                this.translateService.instant(
                  'detailFields.fileDownload.filesize_download_limit'
                )
              );
            } else {
              this.toastr.error(
                this.translateService.instant('detailFields.fileDownload.warn')
              );
            }
            row.actionsDisabled = false;
            row.downloadLoading = false;
            this.cd.detectChanges();
          },
        })
    );
  }
}
