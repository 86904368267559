<app-thumbnail
  [img]="
    '../../../assets/images/bg-telekom-real-estate-startseite-alternative.png'
  "
  [headertitle]="'Telekom RealEstate'"
  [title]="'Wir geben Raum.'"
  [introtext]="companyIntro">
</app-thumbnail>

<div>
  <app-fact-wrapper class="padding-home"></app-fact-wrapper>
</div>
<app-about class="margin-home"></app-about>
