<su-slot-dialog-title [title]="'Filter'"></su-slot-dialog-title>

<su-slot-dialog-content>
  <div card-content class="dialog">
    <form (ngSubmit)="validateForm()" [formGroup]="form">
      <div [hidden]="filledForm">
        <formly-form
          [fields]="filterFields"
          [form]="form"
          [model]="formModel"
          [options]="options"></formly-form>
      </div>
    </form>
  </div>
</su-slot-dialog-content>

<su-slot-dialog-footer [footer]="dialogFooterConfig"> </su-slot-dialog-footer>
