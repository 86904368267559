<div class="row">
  <div class="col-12 margin-left-130" class="margin-home">
    <app-quick-text
      [title]="'Über Telekom RealEstate'"
      [imgUrl]="'assets/images/undraw_who1.svg'"
      [buttonLink]="'../who'"
      [buttonText]="'Über uns'"
      [buttonVariant]="'primary'">
      <p>
        Telekom RealEstate erbringt Dienstleistungen im Bereich Asset Management
        und Portfoliomanagement für alle angemieteten und im Eigentum
        befindlichen Objekte in Deutschland für die Deutsche Telekom AG.
      </p>
    </app-quick-text>
  </div>
</div>
