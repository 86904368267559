<!DOCTYPE html>
<html lang="de">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>MyLocation-MailTemplate</title>
  </head>
  <body class="border">
    <table aria-label="telekom-banner" role="presentation" style="width: 664px">
      <img alt="telekom-banner" src="assets/images/telekom-banner.jpg" />
    </table>
    <table aria-label="info" style="width: 664px; border: medium none">
      <tbody>
        <tr>
          <th
            style="text-align: left; border: medium none; white-space: nowrap"
          >
            <span style="font-weight: bold; font-size: 8pt; font-family: Arial, Sans-serif"
              >{{ address.city }} - {{ address.streetName }} {{ address.houseNumber }}
            </span>
          </th>
          <th style="border: medium none; text-align: right">
            <span style="font-weight: bold; font-size: 8pt; font-family: Arial, Sans-serif"
              >{{ date | date: 'dd.MM.yyyy' }}
            </span>
          </th>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <p
      style="
        padding-left: 5.4pt;
        font-weight: bold;
        font-size: 12pt;
        font-family: 'TeleNeo Office', sans-serif;
        line-break: anywhere;
      "
    >
      {{ subject }}
    </p>
    <br />
    <br />
    <br />
    <div
      style="
        padding-left: 5.4pt;
        font-size: 12pt;
        font-family: 'TeleNeo Office', sans-serif;
        line-break: anywhere;
      "
      [innerHTML]="emailContent ? (emailContent | safeHtml) : ('location.myLocation.location_notification.enter_text_here' | translate)"
    >
    </div>
    <br />
    <br />
    <br />
    <table
      aria-label="greeting"
      role="presentation"
      style="border: medium none"
    >
      <tbody>
        <tr>
          <td style="padding-left: 5.4pt; border: medium none">
            <span
              style="font-size: 12pt; font-family: 'TeleNeo Office', sans-serif"
              >Mit freundlichen Grüßen,
            </span>
          </td>
        </tr>
        <tr>
          <td style="padding-left: 5.4pt; border: medium none">
            <span
              style="font-size: 12pt; font-family: 'TeleNeo Office', sans-serif"
              >Ihre GSUS-Real Estate Management und Ihre ISS
            </span>
          </td>
        </tr>
        <tr>
          <td style="padding-left: 5.4pt; border: medium none">
            <img src="assets/images/telekom-iss-logo.png" alt="" />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <br />
    <br />
    <table
      aria-label="gsus-services"
      role="presentation"
      style="width: 664px; border: medium none; background: #272727"
    >
      <tbody>
        <tr>
          <td style="border: medium none">
            <p style="margin: 6pt 0pt 0pt 6pt">
              <strong
                ><span style="font-family: Arial, Sans-serif; color: #e20074"
                  >Unsere Services beauftragen Sie unter:
                </span></strong
              >
            </p>
          </td>
        </tr>
        <tr>
          <td style="border: medium none">
            <p style="margin: 0pt 0pt 0pt 6pt">
              <span style="font-family: Arial, Sans-serif; color: white"
                >Helpdesk: 0800 8088 909
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="border: medium none">
            <p style="margin: 0pt 0pt 0pt 6pt">
              <span style="font-family: Arial, Sans-serif; color: white"
                >MyPortal, Rubrik „Gebäudedienste“
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="border: medium none">
            <p style="margin: 0pt 0pt 0pt 6pt">
              <span style="font-family: Arial, Sans-serif; color: white"
                >MitarbeiterApp, Rubrik „Gebäudestörung“
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="border: medium none">
            <p style="margin: 0pt 0pt 6pt 6pt">
              <span style="font-family: Arial, Sans-serif; color: white"
                >Besuchen Sie auch unsere YAM-Seite: </span
              ><a
                href="https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fyam-united.telekom.com%2Fpages%2Fgsus-real-estate-management-customers&amp;data=05%7C01%7Cp.scharke%40ptsgroup.de%7C826fd1d6796249a7d01408db35c5fd2d%7C3c0d136cd7c84a34ae6f8c8ecfba9340%7C0%7C0%7C638162896685619087%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&amp;sdata=R9LXdVEHDSpkKhW%2FDKxFU7ZvV1NGZa26nPsDY3ihwZY%3D&amp;reserved=0"
                originalsrc="https://yam-united.telekom.com/pages/gsus-real-estate-management-customers"
                shash="VyKvjjG5GSlyRFVI2ImRLJZsFSw6MXI9X9//XLjlc46n1J20KHlT6OqE1C+qjU6UQJXXKNtl8fmeQogYG4BdRiMoZNurOGj66uV3D9i1iva/CnPqp0u+0SA0ieqTz+HaX1OH5MvLm5fJpnsA8uvby0kiEl/GqzNxHZ2h5TV8KFI="
                ><span style="font-family: Arial, Sans-serif; color: #009de0"
                  >GSUS – Real Estate Management Customers
                </span></a
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <label id="BottomText"></label><br />
    <strong>Hinweis</strong>: Sie erhalten diese Email, da Sie dem Standort „{{ address.city }} - {{ address.streetName }} {{ address.houseNumber }}“
    zugeordnet sind.<br />
  </body>
</html>
