import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import {
  ArchObjHierarchyViewModel,
  ArchObjLocationInformationViewModel,
  BaseSearchOptions,
  BuildingFaultIncidentRequest,
  IncidentClient,
  LocationClient,
  LocationSearchEntryViewModel,
  ResultWrapperOfBuildingFaultIncidentViewModel,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Tab } from '@affinis/smartus-components/lib/tabs/tabs.components.types';
import moment from 'moment';
import { LocationInformationEditComponent } from '../components';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { UserService } from '@core/services/oauth-service/user.service';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { rxSubscriptionContainerMixin } from '@shared/utils/rx-subscription-container.mixin';
import { LocationInfoCategory } from '../location.constants';
import { PageEvent } from '@angular/material/paginator';
import {
  ITableConfig,
  ITableGridConfig,
  ITablePagingConfig,
} from '@shared/components/table-base/table-base.types';
import { ToastrService } from 'ngx-toastr';
import { DeviceService } from '@core/services/device-service/device.service';

@Component({
  selector: 'app-my-location',
  templateUrl: './my-location.component.html',
  styleUrls: ['./my-location.component.scss'],
})
export class MyLocationComponent
  extends rxSubscriptionContainerMixin()
  implements OnInit
{
  config: ITableConfig = <ITableConfig>{
    grid: <ITableGridConfig>{
      showToolbar: true,
      columnResizable: false,
      paging: <ITablePagingConfig>{
        pageIndex: 0,
        pageSize: 50,
        showPaginator: true,
      },
    },
  };

  query: BaseSearchOptions = {
    pagingOptions: {
      skip:
        this.config.grid.paging.pageIndex * this.config.grid.paging.pageSize,
      take: this.config.grid.paging.pageSize,
    },
  };

  form = new FormGroup({});
  formFields: Array<FormlyFieldConfig>;
  loading = false;
  archObjId = '';
  isLoading = false;
  loadedInformation = false;
  editLocationInfoTitle = this.translateService.instant(
    'location.myLocation.location_information.title'
  );
  LocationInfoCategory = LocationInfoCategory;
  locationInfos = [];

  locationInfosHints = [];
  locationInfosServices = [];
  locationInfosAdditionalServices = [];
  locationInfosUnpublished = [];

  public locationCategories = [];

  formModel = {};

  public selectedLocation: ArchObjHierarchyViewModel = null;

  cardSearch: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'location.myLocation.label__search_location'
        ),
      },
    },
  };

  cardMap: CardConfig = {
    header: {
      title: {
        label: 'Details',
      },
    },
  };

  cardHints: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'location.myLocation.location_information.hints'
        ),
      },
    },
  };

  cardServices: CardConfig = {
    header: {
      title: {
        label: 'Services',
      },
    },
  };

  cardAdditionalServices: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'location.myLocation.location_information.additional_services'
        ),
      },
    },
  };

  cardUnpublishedInfos: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'location.myLocation.location_information.unpublished_infos'
        ),
      },
    },
  };

  tabs: Tab[] = [
    {
      label: this.translateService.instant('location.myLocation.label__search'),
      id: 'suche',
      onClick: (id: string) => this.onTabClick(id),
    },
    {
      label: this.translateService.instant('location.myLocation.favorites.title'),
      id: 'favorites',
      onClick: (id: string) => this.onTabClick(id),
    },
    {
      label: this.translateService.instant(
        'location.myLocation.label__location_information'
      ),
      id: 'standort_information',
      onClick: (id: string) => this.onTabClick(id),
      disabled: true,
    },
    {
      label: this.translateService.instant(
        'location.myLocation.label__malfunction_messages'
      ),
      id: 'reports',
      onClick: (id: string) => {
        this.onTabClick(id);
        this.getIncidents();
      },
      disabled: true,
    },
  ];

  activeTab = this.tabs[0].id;

  onTabClick(value: string) {
    this.activeTab = value;
  }

  columns: MtxGridColumn[] = [
    {
      header: this.translateService.instant(
        'location.myLocation.malfunction_messages.ticket_no'
      ),
      field: 'ticketNumber',
      sortable: true,
    },
    {
      header: this.translateService.instant(
        'location.myLocation.malfunction_messages.title'
      ),
      field: 'name',
      sortable: true,
    },
    {
      header: this.translateService.instant(
        'location.myLocation.malfunction_messages.description'
      ),
      field: 'description',
    },
    {
      header: this.translateService.instant(
        'location.myLocation.malfunction_messages.room'
      ),
      field: 'room',
      width: '150px'
    },
    {
      header: this.translateService.instant(
        'location.myLocation.malfunction_messages.floor'
      ),
      field: 'floor',
      width: '120px'
    },
    {
      header: this.translateService.instant(
        'location.myLocation.malfunction_messages.section'
      ),
      field: 'structuralPart',
      width: '150px'
    },
    {
      header: this.translateService.instant(
        'location.myLocation.malfunction_messages.created_on'
      ),
      field: 'createdOn',
      sortable: true,
      formatter: (data: any) =>
        data.createdOn
          ? moment(data.createdOn)
              .locale(this.translateService.instant('locale.locale'))
              .format(
                this.translateService.instant('locale.moment__date_format')
              )
          : '--',
    },
  ];

  @ViewChild('favoriteIconTemplate', { static: true })
  favoriteIconTemplateRef!: TemplateRef<void>;

  disableFavoriteIcon = false;

  columnsFavorites: MtxGridColumn[] = [];


  locationIncidents: ResultWrapperOfBuildingFaultIncidentViewModel;

  headline = this.translateService.instant(
    'location.myLocation.label__myLocation'
  );

  isDataManager = false;

  isBuildingFaultButtonDisabled = true;

  public locationFavorites: LocationSearchEntryViewModel[];

  public isMobile: boolean;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private fieldService: LocationFormlyFieldService,
    private locationClient: LocationClient,
    private breadcrumbService: BreadcrumbService,
    private incidentClient: IncidentClient,
    private dialog: MatDialog,
    private userService: UserService,
    private toastr: ToastrService,
    private deviceService: DeviceService
  ) {
    super();
    this.getFavorites();

    this.deviceService.isMobile$.subscribe({
      next: res => {
        this.isMobile = res;
      }
    })

    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);
    this.locationClient.getLocationInfoCategories().subscribe({
      next: res => {
        this.locationCategories = res;
      },
    });
    this.pushSubscription(
      this.userService
        .checkIfUserHasRole(UserRoles.SMARTPORTAL_LOCATION_DATA_MANAGER)
        .subscribe(hasRole => (this.isDataManager = hasRole))
    );
    this.pushSubscription(
      this.userService
        .checkIfUserHasRole(UserRoles.SMARTPORTAL_LOCATION_MAIL)
        .subscribe(hasRole => {
          if (hasRole) {
            this.tabs.push({
              label: this.translateService.instant(
                'location.myLocation.label__email_employee'
              ),
              id: 'email_employee',
              onClick: (id: string) => this.onTabClick(id),
              disabled: this.selectedLocation ? false : true,
            });
          } else if (this.tabs.find(tab => tab.id === 'email_employee')) {
            this.tabs = this.tabs.filter(tab => tab.id !== 'email_employee');
          }
        })
    );
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadedInformation = false;
        this.activeTab = this.tabs[0].id;
        if (this.selectedLocation) {
          this.onSelect(this.selectedLocation.dtArchObjAddress[0].archObjId);
        }
      }
    });
    this.loading = true;
    this.locationClient.getArchobjOfCurrentUser().subscribe({
      next: (response: ArchObjHierarchyViewModel) => {
        this.isLoading = false;
        this.formFields = [
          ...this.fieldService.autocompleteSearch(
            false,
            response,
            true,
            (id, onInitSearch) => this.onSelect(id, onInitSearch),
            null,
            () => this.onDelete()
          ),
        ];
      },
      error: (error: any) => {
        this.handleError(error);
      },
      complete: () => (this.isLoading = false),
    });

    this.columnsFavorites = [
      {
        header: this.translateService.instant('location.myLocation.favorites.WE-No'),
        field: 'id',
      },
      {
        header: this.translateService.instant('location.autocomplete.zip'),
        field: 'postalCode',
      },
      {
        header: this.translateService.instant('location.autocomplete.city'),
        field: 'city',
      },
      {
        header: this.translateService.instant('location.autocomplete.street'),
        field: 'streetName',
      },
      {
        header: this.translateService.instant('location.myLocation.favorites.favorite'),
        field: 'fav',
        cellTemplate: this.favoriteIconTemplateRef,
      },
    ];
  }

  onDelete(): () => void {
    this.selectedLocation = null;
    this.isBuildingFaultButtonDisabled = true;
    this.tabs[2].disabled = true;
    this.tabs[3].disabled = true;
    if (this.tabs[4]) {
      this.tabs[4].disabled = true;
    }
    return null;
  }

  public get addressString() {
    if (
      !this.selectedLocation?.dtArchObjAddress ||
      !this.selectedLocation?.dtArchObjAddress[0].address
    ) {
      return '';
    }

    const address = this.selectedLocation?.dtArchObjAddress[0].address;
    return `${address.streetName}, ${address.postalCode} ${address.city}`;
  }

  private handleError(error: any) {
    this.isLoading = false;
    console.error('[MyLocationComponent] handleError:', error);
  }

  public onSelect(archObjId: string, onInitSearch?: boolean): void {
    this.isLoading = true;
    this.locationClient.getArchobjById(archObjId).subscribe({
      next: (response: ArchObjHierarchyViewModel) => {
        console.log('[MyLocationComponent] onSelect:', response);
        this.selectedLocation = response;
        this.tabs[2].disabled = false;
        this.tabs[3].disabled = false;
        if (this.tabs[4]) {
          this.tabs[4].disabled = false;
        }
        this.isBuildingFaultButtonDisabled = false;
        if (!onInitSearch) {
          this.activeTab = this.tabs[2].id;
        }
      },
      error: (error: any) => this.handleError(error),
      complete: () => {
        this.isLoading = false;
        if (
          window.location.hash === '#information' &&
          !this.loadedInformation
        ) {
          this.activeTab = this.tabs[2].id;
          this.loadedInformation = true;
        }
      },
    });
    this.archObjId = archObjId;

    this.getLocationList();
  }

  public getIncidents() {
    this.locationIncidents = {};
    this.isLoading = true;

    const request: BuildingFaultIncidentRequest = {
      archObjId: this.archObjId,
      pagingOptions: this.query.pagingOptions,
    };
    this.incidentClient.getBuildingFaultIncidents(request).subscribe({
      next: res => {
        this.locationIncidents = res;
        this.isLoading = false;
      },
      error: err => {
        this.isLoading = false;
        console.log(err);
      },
    });
  }

  getNextPage(e: PageEvent) {
    this.config.grid.paging.pageIndex = e.pageIndex;
    this.config.grid.paging.pageSize = e.pageSize;

    this.query = {
      ...this.query,
      pagingOptions: {
        skip:
          this.config.grid.paging.pageIndex * this.config.grid.paging.pageSize,
        take: this.config.grid.paging.pageSize,
      },
    };

    this.getIncidents();
  }

  public editLocationInformation() {
    const locationInfo: ArchObjLocationInformationViewModel = {
      archObjId: this.selectedLocation.dtArchObjAddress[0].archObjId,
    };

    this.openEditDialog(locationInfo, false);
  }

  openEditDialog(item: ArchObjLocationInformationViewModel, isUpdate: boolean) {
    const editDialogRef = this.dialog.open(LocationInformationEditComponent, {
      minWidth: '40vw',
      minHeight: '60vh',
      data: {
        locationInfo: item,
        title: this.editLocationInfoTitle,
        update: isUpdate,
        categories: this.locationCategories,
        locationInfos: this.locationInfos,
      },
    });

    editDialogRef.afterClosed().subscribe({
      next: res => {
        if (res) {
          this.getLocationList();
        }
      },
    });
  }

  public deleteLocationInformation(id?: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'smartportal-dialog',
      width: '500px',
      data: {
        type: 'okDismissButton',
        title: this.translateService.instant(
          'location.myLocation.location_information.delete_dialog.title'
        ),
        message: this.translateService.instant(
          'location.myLocation.location_information.delete_dialog.message'
        ),
        okButtonText: this.translateService.instant('shared.confirm'),
        dismissButtonText: this.translateService.instant('shared.cancel'),
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.isLoading = true;
          this.locationClient.deleteLocationInformation(id).subscribe({
            next: () => this.getLocationList(),
            error: error => this.handleError(error),
          });
        }
      },
    });
  }

  public getLocationList() {
    this.isLoading = true;
    this.locationInfosHints = [];
    this.locationInfosServices = [];
    this.locationInfosAdditionalServices = [];
    this.locationInfosUnpublished = [];
    this.locationClient.getLocationInfo(this.archObjId).subscribe({
      next: res => {
        this.locationInfos = res;
        this.locationInfos.forEach(item => {
          const today = new Date();
          const itemValidFrom = new Date(item.validFrom);
          if (
            !item.isPublished ||
            (item.category.id === LocationInfoCategory.LocationHint &&
              itemValidFrom > today)
          ) {
            this.locationInfosUnpublished.push(item);
            return;
          }

          item.text = item.text.replace(/\n/g, '<br>');
          
          switch (item.category.id) {
            case LocationInfoCategory.LocationHint:
              this.locationInfosHints.push(item);
              break;
            case LocationInfoCategory.AdditionalServices:
              this.locationInfosAdditionalServices.push(item);
              break;
            default:
              this.locationInfosServices.push(item);
              break;
          }
        });
        this.isLoading = false;
      },
      error: error => this.handleError(error),
    });
  }

  public createBuildingFault() {
    if (!this.selectedLocation) {
      return;
    }
    const url = `create/helpdesk?archObjId=${this.selectedLocation.archObjId}`;
    this.router.navigateByUrl(url);
  }

  public deleteFavorite(event?: MouseEvent, row?: LocationSearchEntryViewModel) {
    event.stopPropagation();

    const deleteFavoriteDialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'smartportal-dialog',
      data: {
        type: 'okDismissButton',
        title: this.translateService.instant('location.myLocation.favorites.delete'),
        message: this.translateService.instant('location.myLocation.favorites.delete_message'),
        okButtonText: this.translateService.instant('shared.confirm'),
        dismissButtonText: this.translateService.instant('shared.cancel')
      },
    });

    deleteFavoriteDialogRef.afterClosed().subscribe({
      next: res => {
        if (res) {
          this.locationClient.deleteFavorite(row.id).subscribe({
            next: () => {
              this.getFavorites();
              if(row.id === this.selectedLocation.archObjId){
                this.selectedLocation.isFavorite = !this.selectedLocation.isFavorite;
                this.disableFavoriteIcon = false;
              }
              this.toastr.success(this.translateService.instant('location.myLocation.favorites.delete_success'))
            },
            error: () => {
              this.toastr.success(this.translateService.instant('location.myLocation.favorites.delete_error'))
            }
          });
        }
      }
    });
  }

  public toggleFavorite() {
    this.disableFavoriteIcon = true;
    if(this.selectedLocation.isFavorite){
      this.locationClient.deleteFavorite(this.selectedLocation?.archObjId).subscribe({
        next: () => {
          this.getFavorites();
          this.selectedLocation.isFavorite = !this.selectedLocation.isFavorite;
          this.disableFavoriteIcon = false;
        }
      });
    } else {
      this.locationClient.createFavorite(this.selectedLocation?.archObjId).subscribe({
        next: () => {
          this.getFavorites();
          this.selectedLocation.isFavorite = !this.selectedLocation.isFavorite;
          this.disableFavoriteIcon = false;
        }
      });
    }
  }

  public getFavorites() {
    this.locationClient.getFavorites().subscribe({
      next: (favorites: any) => {
        this.locationFavorites = favorites;
        this.fieldService.getDefaultList();
      }
    });
  }
}
