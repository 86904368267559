import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { ContactSearchFormlyFieldService } from '@core/services/contact-search-formly-field-service/contact-search-formly-field.service';
import { FileService } from '@core/services/file-service/file.service';
import { FormlyFieldBaseService } from '@core/services/formly-service/formly-field.service';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { IbpdiHelperService } from '@core/services/translate-service/ibpdi-helper.service';
import { TicketCreateBaseComponent } from '@features/tickets/components/ticket-create/ticket-create.base';
import {
  auxAddress,
  concerningContact,
  editor,
  emailcc,
  extraConfirm,
  fileAttachments,
  incidentInformation,
  requestingContact,
  searchContact,
  searchLocation,
  targetLocation,
} from '@mandants/telekom/features/tickets/ticket-create/field-configs';
import {
  FormModes,
  TicketCreateQueryParams,
} from '@mandants/telekom/features/tickets/ticket-create/ticket-create.types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  AddIncidentDTO,
  ArchObjHierarchyViewModel,
  CreateEmployeeNotificationDTO,
  IncidentClient,
  IncidentType,
  LocationClient,
  OptionSetClient,
} from '@core/api';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { ToastrService } from 'ngx-toastr';
import { CiamService } from '@core/services/ciam-service/ciam-service';

@Component({
  selector: 'app-ticket-create',
  templateUrl:
    '../../../../../app/features/tickets/components/ticket-create/ticket-create.base.html',
  styleUrls: [],
})
export class TicketCreateTelekomComponent extends TicketCreateBaseComponent {
  public override title = '';
  public override description = '';
  public override descriptionStyle: string;
  public override descriptionContainerStyle: string;
  public override subDescription = '';
  public override subDescriptionStyle: string;
  public override subDescriptionContainerStyle: string;
  public override formFields: Array<FormlyFieldConfig> = [];
  public override successMessage: string;

  descriptionFilledForm = this.translateService.instant(
    'ticket.create.confirm.p__info_summary'
  );

  labelButton = this.translateService.instant('forward');

  canSubmitForm: boolean;

  override card: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
    footer: {
      button1: {
        label: this.labelButton,
        disabled: false,
        onClick: (event: any) => {
          console.log(event);
          this.canSubmitForm = this.canSubmit();
          if (this.canSubmitForm) this.validateForm();
        },
      },
    },
  };

  public isHelpdesk = false;

  public mode: FormModes;

  constructor(
    injector: Injector,
    protected incidentClient: IncidentClient,
    protected fileService: FileService,
    public toastr: ToastrService,
    public locationFormlyFieldService: LocationFormlyFieldService,
    public contactSearchFormlyFieldService: ContactSearchFormlyFieldService,
    protected route: ActivatedRoute,
    protected breadcrumbService: BreadcrumbService,
    private formlyFieldService: FormlyFieldBaseService,
    private ciamService: CiamService,
    protected optionSetClient: OptionSetClient,
    private ibpdiHelperService: IbpdiHelperService,
    private locationClient: LocationClient
  ) {
    super(injector);

    let currentUserEmail = this.msalService.instance.getActiveAccount()?.idTokenClaims?.email as string;

    let weNo: string | undefined;

    const parts = this.router.url.split('/');
    const lastPart = parts[parts.length - 1];
    this.mode = lastPart.split('?')[0] as FormModes;

    this.route.queryParams.subscribe({
      next: (params: Partial<TicketCreateQueryParams>) => {
        weNo = params.archObjId;

        if (this.mode === 'helpdesk') {
          this.isHelpdesk = true;
          this.successMessage = this.translateService.instant(
            'ticket.create.success.message_helpdesk'
          );
          this.title = this.translateService.instant(
            'ticket.create.helpdesk.title'
          );
          this.description = this.translateService.instant(
            'ticket.create.p__info_helpDeskCreate'
          );
          this.subDescription = this.translateService.instant(
            'ticket.create.p__info_helpDeskNetworkProblem'
          );
          this.descriptionStyle = "background-color: #E20074; color: white; border-radius: 5px; padding: 15px;";
          this.descriptionContainerStyle = "width: 100%; max-width: 840px; border-radius: 15px; padding: 0 15px;";
          this.subDescriptionStyle = "background-color: #E20074; color: white; border-radius: 5px; padding: 15px;";
          this.subDescriptionContainerStyle = "width: 100%; max-width: 840px; border-radius: 15px; padding: 0 15px;";
          this.showTicketCreateData = true;
          this.showExtraConfirmFormFields = false;
          this.formFields = this.setFormFieldsHelpdesk(this.mode, weNo, currentUserEmail);
          this.locationClient.getArchobjOfCurrentUser().subscribe({
            next: (response: ArchObjHierarchyViewModel) => {
              this.formFields = this.setFormFieldsHelpdesk(
                this.mode,
                response?.archObjId,
                currentUserEmail
              );
            },
            error: (error: any) => {
              console.log(error);
            },
          });
        } else {
          this.title = this.translateService.instant(
            'ticket.create.contactTitle'
          );
          this.description = this.translateService.instant(
            'ticket.create.p__info_contactCreate'
          );
          this.showTicketCreateData = false;
          this.showExtraConfirmFormFields = true;

          this.formFields = [
            ...this.headline(),
            ...incidentInformation(this.translateService, this.mode),
            ...fileAttachments(this.translateService),
            ...targetLocation(this.translateService),
            ...auxAddress(this.translateService),
            ...concerningContact(this.translateService),
            ...requestingContact(this.translateService),
          ];
        }
        this.breadcrumbService.setBreadcrumbs([{ label: this.title }]);
      },
    });
  }

  headline(): Array<FormlyFieldConfig> {
    return [
      {
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'ticket.create.label__confirmHeadline'
              ),
              visibleInConfirm: true,
            },
          },
        ],
      },
    ];
  }

  override extraConfirmFormFields: Array<FormlyFieldConfig> = [
    {
      key: 'subscribeNewsletter',
      type: 'checkbox',
      wrappers: ['checkbox'],
      defaultValue: false,
      props: {
        label: this.translateService.instant(
          'ticket.create.confirm.label__newsletter_checkbox'
        ),
        appearance: 'outline',
      },
    },
    {
      key: 'confirmContent',
      type: 'checkbox',
      wrappers: ['checkbox'],
      defaultValue: false,
      props: {
        label: this.translateService.instant(
          'ticket.create.confirm.label__confirm_checkbox'
        ),
        appearance: 'outline',
      },
      validators: {
        validation: ['requiredTrue'],
      },
    },
    {
      key: 'acceptDsgvo',
      type: 'checkbox',
      wrappers: ['checkbox'],
      defaultValue: false,
      props: {
        label: this.translateService.instant(
          'ticket.create.confirm.label__dsgvo'
        ),
        appearance: 'outline',
      },
      validators: {
        validation: ['requiredTrue'],
      },
    },
  ];

  protected onSelectLocation(location: any) {
    console.log(location);
  }

  public onEmailChange(emails: string) {
    this.form.get('ccemail')?.setValue(emails as never);
  }

  protected canSubmit(): boolean {
    const MAX_FILE_SIZE = 20;
    if ((this.form.value as any).attachments.length === 0) {
      return true;
    }

    const at = (this.form.value as any).attachments;
    let isValidFiles = true;
    at.forEach(element => {
      if (element.attachment === undefined || element.attachment === null) {
        isValidFiles = false;
      }
    });

    if (isValidFiles) {
      this.formlyFieldService.unsetNotSelectedFileError();
      const [isValid, message, title] = this.fileService.validateTotalFileSize(
        at,
        (accumulator, current) => accumulator + current.attachment.size,
        MAX_FILE_SIZE
      );
      if (!isValid) {
        this.toastr.error(message, title);
      }
      return isValid;
    } else {
      this.formlyFieldService.setNotSelectedFileError();
      const element = document.getElementById('fileAttachment');
      element.scrollIntoView({ behavior: 'smooth' });
    }

    return isValidFiles;
  }

  protected override prepareSubmit = (data: any) => {
    const d = data;

    if (this.isHelpdesk === true) {
      const employeeNotification: CreateEmployeeNotificationDTO = {};
      employeeNotification.incidentTitle = d.incidentTitle;
      employeeNotification.incidentDescription = d.incidentDescription;
      employeeNotification.incidentType = IncidentType.Kundenprozesse;
      employeeNotification.roomNumber = d.searchLocation.roomNumber;
      employeeNotification.structuralPart = d.searchLocation.section;
      employeeNotification.deviatingAddress = d.searchLocation.auxaddress;
      employeeNotification.floor = d.searchLocation.floor;
      employeeNotification.mails = d.ccemail;
      employeeNotification.targetLocation = {
        archObjId: d.searchLocation.searchResult.id,
      };
      employeeNotification.creator = {
        id: d.searchContact.searchResult.id,
      };
      if(d.editor.searchResult?.id) {
        employeeNotification.reporter = {
          id: d.editor.searchResult?.id
        };
      }

      console.log(employeeNotification)

      return {employeeNotification}
    }

    if (d.incidentClass === 'Fläche extern vermieten') {
      d.incidentType = IncidentType.Mietmanagement;
    } else {
      d.incidentType = IncidentType.Eigentuemerbelange;
    }

    function setToNullIfEmpty(obj) {
      console.log(obj);
      const isNullish = Object.values(obj).every(
        value => !(value !== '' && value !== null)
      );
      return isNullish ? null : obj;
    }

    if (d.targetLocation !== undefined) {
      d.targetLocation = setToNullIfEmpty(data.targetLocation);
    }

    const subscribeNewsletter = extraConfirm(this.translateService).find(
      f => f.key === 'subscribeNewsletter'
    )?.formControl?.value;

    if (
      d.concerningLocation &&
      d.concerningLocation.powerOfDisposition === 'Eigentümer'
    ) {
      d.requestingContact = d.concerningContact;
    }

    d.requestingContact.subscribeNewsletter = subscribeNewsletter;

    d.powerOfDisposition = d.concerningLocation?.powerOfDisposition;


    d.ciamId = this.ciamService.getCiamId();

    const incidentDTO: AddIncidentDTO = d;

    return { incidentDTO };
  };

  public override clientEndpoint = data => {
    if (this.mode == 'helpdesk') {
      return this.incidentClient.createEmployeeNotification(data);
    }
    return this.incidentClient.createIncident(data);
  };

  public setFormFieldsHelpdesk(mode: FormModes, weNo: string, currentUserEmail: string) {
    return [
      ...incidentInformation(this.translateService, mode),
      ...fileAttachments(this.translateService),
      ...searchLocation(
        this.translateService,
        this.optionSetClient,
        this.cd,
        this.ibpdiHelperService,
        this.locationFormlyFieldService,
        this.onSelectLocation,
        weNo
      ),
      ...searchContact(
        this.translateService,
        this.contactSearchFormlyFieldService,
        mode,
        currentUserEmail
      ),
      ...editor(this.translateService, this.contactSearchFormlyFieldService),
      ...emailcc(this.translateService, this.onEmailChange.bind(this)),
    ];
  }

  public override getStatusPath() {
    if (this.mode === 'helpdesk') {
      return '/dashboard';
    } else {
      const parts = this.router.url.split('/'); //  ['', 'ticket', 'any']
      const modulePath = parts[1];
      let newRoute = '';
      if (parts.length > 2) {
        newRoute = `/${modulePath}/status`;
      } else {
        newRoute = '/ticket/status';
      }
      return newRoute;
    }
  }
}
