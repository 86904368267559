import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export function additionalInformation(
  translateService: TranslateService
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__further_details'
            ),
            visibleInConfirm: true
          },
        },
        {
          type: 'input',
          key: 'producer',
          props: {
            label: translateService.instant('ticket.create.label__producer'),
            visibleInConfirm: true,
            maxLength: 50,
          },
        },
        {
          type: 'flex-layout',

          fieldGroup: [
            {
              type: 'input',
              key: 'keyNumber',
              props: {
                label: translateService.instant(
                  'ticket.create.label__keyNumber'
                ),
                visibleInConfirm: true,
                maxLength: 250,
                minLength: 3,
              },
            },
            {
              type: 'number',
              key: 'keyQuantity',
              props: {
                label: translateService.instant(
                  'ticket.create.label__keyQuantity'
                  ),
                visibleInConfirm: true,
                required: true,
                pattern: /^[1-9][0-9]*$/
              },
              validation: {
                messages: {
                  pattern: () =>
                    translateService.instant('formErrors.key_quantity.pattern'),
                },
              },
            },
          ],
        },
        {
          key: 'assetNumber',
          fieldGroup: [
            {
              type: 'input',
              key: 'assetNumber',
              props: {
                label: translateService.instant(
                  'ticket.create.label__assetNumber'
                ),
                visibleInConfirm: true,
                maxLength: 50,
              },
            },
          ],
        },
      ],
    },
    {
      wrappers: [],
      fieldGroup: [
        {
          key: 'additionalKey',
          type: 'repeat-multiple-fields',
          props: {
            maxItems: 10,
            addText: translateService.instant(
              'ticket.create.button__order_additional_key'
            ),
          },
          fieldArray: {
            type: 'flex-layout',

            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: translateService.instant(
                    'ticket.create.headline__order_additional_key'
                  ),
                },
              },
              {
                type: 'input',
                key: 'producer',
                props: {
                  label: translateService.instant(
                    'ticket.create.label__producer'
                  ),
                  visibleInConfirm: true,
                  maxLength: 50,
                },
              },
              {
                type: 'flex-layout',

                fieldGroup: [
                  {
                    type: 'input',
                    key: 'keyNumber',
                    props: {
                      label: translateService.instant(
                        'ticket.create.label__keyNumber'
                      ),
                      visibleInConfirm: true,
                      maxLength: 250,
                      minLength: 3,
                    },
                  },
                  {
                    type: 'number',
                    key: 'keyQuantity',
                    props: {
                      label: translateService.instant(
                        'ticket.create.label__keyQuantity'
                        ),
                      visibleInConfirm: true,
                      required: true,
                      pattern: /^[1-9][0-9]*$/
                    },
                    validation: {
                      messages: {
                        pattern: () =>
                          translateService.instant('formErrors.key_quantity.pattern'),
                      },
                    },
                  },
                ],
              },
              {
                key: 'assetNumber',
                fieldGroup: [
                  {
                    type: 'input',
                    key: 'assetNumber',
                    props: {
                      label: translateService.instant(
                        'ticket.create.label__assetNumber'
                      ),
                      visibleInConfirm: true,
                      maxLength: 50,
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      wrappers: [],
      fieldGroup: [
        {
          key: 'additionalInformation',
          fieldGroup: [
            {
              type: 'input',
              key: 'totalKeys',
              props: {
                label: translateService.instant(
                  'ticket.create.label__totalKeys'
                ),
                visibleInConfirm: true,
                maxLength: 50,
                readonly: true,
              },
            },
            {
              type: 'input',
              key: 'additionalInformations',
              props: {
                label: translateService.instant(
                  'ticket.create.label__additional_informations'
                ),
                visibleInConfirm: true,
                maxLength: 250,
              },
            },
          ],
        },
      ],
    },
  ];
}
