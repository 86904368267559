import { AfterViewInit, Component, Injector, Input } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TicketCreateQueryParams } from '@mandants/telekom/features/tickets/ticket-create/ticket-create.types';
import { IncidentClient, LocationClient, OptionSetClient } from '@core/api';
import { FileService } from '@core/services/file-service/file.service';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { ActivatedRoute } from '@angular/router';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { TicketCreateTelekomComponent } from '../ticket-create.component';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { ToastrService } from 'ngx-toastr';
import { ContactSearchFormlyFieldService } from '@core/services/contact-search-formly-field-service/contact-search-formly-field.service';
import { FormlyFieldBaseService } from '@core/services/formly-service/formly-field.service';
import { IbpdiHelperService } from '@core/services/translate-service/ibpdi-helper.service';
import { CiamService } from '@core/services/ciam-service/ciam-service';
import { additionalInformation } from '../field-configs/order-access/additional-information.field-config';
import { closureLocation } from '../field-configs/order-access/closure-location.field-config';
import { ordererContact } from '../field-configs/order-access/orderer-contact.field-config';
import { shippingAddress } from '../field-configs/order-access/shipping-address.field-config';
import { fileAttachments } from '../field-configs/ticket-create-file-attachments.field-configs';
import { emailcc } from '../field-configs/ticket-create-email-cc.field-config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-access',
  templateUrl: './order-access.component.html',
  styleUrls: ['./order-access.component.scss'],
})
export class TicketCreateOrderAccessTelekomComponent extends TicketCreateTelekomComponent implements AfterViewInit {
  totalKeys: number;

  @Input() override navigation = true;

  @Input() override id: any;

  override loading = false;

  override form = new FormGroup({});

  override formModel: any = {};

  override formFields: FormlyFieldConfig[];

  override filledForm: FormlyFieldConfig[] | null;

  override showExtraConfirmFormFields: boolean;

  override title: string;

  override description: string;

  override showTicketCreateData: boolean;

  override patchValue: Observable<any>;

  override options: FormlyFormOptions = {
    formState: {
      mainModel: this.formModel,
    },
  };

  override card: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
    footer: {
      button1: {
        label: this.translateService.instant('forward'),
        disabled: false,
        onClick: () => {
          this.validateForm();
        },
      },
    },
  };

  cardConfirm: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  private isUpdatingTotalKeysInternally = false;

  constructor(
    injector: Injector,
    incidentClient: IncidentClient,
    fileService: FileService,
    toastr: ToastrService,
    locationFormlyFieldService: LocationFormlyFieldService,
    contactSearchFormlyFieldService: ContactSearchFormlyFieldService,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    formlyFieldService: FormlyFieldBaseService,
    ciamService: CiamService,
    optionSetClient: OptionSetClient,
    ibpdiHelperService: IbpdiHelperService,
    locationClient: LocationClient,
    translateService: TranslateService
  ) {
    super(
      injector,
      incidentClient,
      fileService,
      toastr,
      locationFormlyFieldService,
      contactSearchFormlyFieldService,
      route,
      breadcrumbService,
      formlyFieldService,
      ciamService,
      optionSetClient,
      ibpdiHelperService,
      locationClient
    );

    let weNo: string | undefined;
    this.route.queryParams.subscribe({
      next: (params: Partial<TicketCreateQueryParams>) => {
        weNo = params.archObjId;

        this.title = this.translateService.instant(
          'ticket.create.order_access.title'
        );
        this.description = this.translateService.instant('ticket.create.order');

        this.formFields = [
          {
            wrappers: [],
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translateService.instant(
                    'ticket.create.headline__order_information'
                  ),
                },
              },
              {
                type: 'input',
                key: 'shortTitle',
                props: {
                  label: this.translateService.instant(
                    'ticket.create.label__short_title'
                  ),
                  visibleInConfirm: true,
                  required: true,
                },
              }
            ]
          },
          ...ordererContact(this.translateService),
          ...closureLocation(
            this.translateService,
            this.locationFormlyFieldService,
            this.onSelectLocation,
            weNo
            ),
          ...shippingAddress(this.translateService),
          ...fileAttachments(this.translateService),
          ...additionalInformation(this.translateService),
          ...emailcc(this.translateService, this.onEmailChange.bind(this)),
        ];
        this.breadcrumbService.setBreadcrumbs([{ label: this.title }]);
      },
    });
  }
  
  ngAfterViewInit() {
    this.totalKeys = this.getTotalKeyQuantity() || 1;
    this.formFields[0]?.form?.valueChanges.subscribe(() => {
      if (!this.isUpdatingTotalKeysInternally) {
        this.updateTotalKeys();
      }
    });
  }
  
  private updateTotalKeys() {
    this.totalKeys = this.getTotalKeyQuantity();
    this.isUpdatingTotalKeysInternally = true;
    this.formFields[0]?.form?.get('additionalInformation.totalKeys')?.setValue(this.totalKeys);
    this.isUpdatingTotalKeysInternally = false;
  }
  
  getTotalKeyQuantity(): number {
    let totalKeys = this.formFields[0]?.form?.value?.keyQuantity;
    this.formFields[0]?.form?.value?.additionalKey.forEach(additionalKey => {
      totalKeys += additionalKey.keyQuantity ?? 0;
    });
    return totalKeys;
  }

  pressBackToEdit() {
    this.filledForm = null;
  }

  showChapter(label: string): boolean {
    if(label === this.translateService.instant('ticket.create.headline__attachment')){
      let showAttachment = false;
      if(this.formFields[0].form.value.attachments.length > 0){
        showAttachment = true;
      }
      return showAttachment;
    }
    return true;
  }

  getAttachmentTypeLabel(value: string) {
    const types = [
      { label: 'Flurstückskarte', value: 'AUG' },
      { label: 'Grundbuchauszug', value: 'AUG' },
      { label: 'Pläne', value: 'PL7' },
      { label: 'Schriftverkehr', value: 'SVK' },
      { label: 'Sonstiges', value: 'SO7' },
    ];
    const foundType = types.find(type => type.value === value);

    if (foundType) {
      return foundType.label;
    } else {
      return null;
    }
  }
}
