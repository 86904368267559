export enum RoutePath {
  ACCESS_ORDERER = 'access-orderer',
  AUTH_CALLBACK = 'authentication/callback',
  DASHBOARD = 'dashboard',
  DOCUMENTS = 'documents',
  DOCUPLOAD = 'docupload',
  FEEDBACK = 'feedback',
  HELPDESK_MYTICKETS = 'helpdesk/my-tickets',
  HELPDESK_MYTICKETS_DETAILS = 'helpdesk/my-tickets/:incidentId',
  IMPRINT = 'imprint',
  LANDING = 'landing',
  LOCATION = 'location/my-location',
  NONE = '',
  PRIVACY = 'privacy',
  PROFILE_DEFAULT = 'profile',
  PROFILE_JSON = 'profile/json',
  TASK = 'task/details/:id',
  TASK_VIEW = 'task/viewDetails/:id',
  TASKS = 'tasks',
  TICKET_CREATE = 'create',
  TICKET_CREATE_HELPDESK = 'create/helpdesk',
  TICKET_CREATE_ORDER_ACCESS = 'create/order-access',
  TICKET_CREATION = 'ticket-create',
  TICKETS = 'tickets',
  TICKETSTATUS = 'ticket/status',
  TICKETSTATUS_ITEM = 'ticket/status/:id',
  LOGOUT = 'logout',
  MAINTENANCE = 'maintenance',
}
