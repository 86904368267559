<su-headline [hidden]="filledForm" h="h1" skin="primary">{{ title }}</su-headline>
<div [hidden]="filledForm" class="sub-headline">{{ description }}</div>

<su-card [card]="card" [hidden]="filledForm">
  <div card-content>
    <form (ngSubmit)="validateForm()" [formGroup]="form">
      <div [hidden]="filledForm">
        <formly-form
          [fields]="formFields"
          [form]="form"
          [model]="formModel"
          [options]="options"></formly-form>
      </div>
    </form>
  </div>
</su-card>

<su-headline *ngIf="filledForm" h="h1" skin="primary">{{ 'ticket.create.order_access.summary' | translate }}</su-headline>
<div class="description" *ngIf="filledForm">
  <p
    [innerText]="'ticket.create.order_access.p__info_summary' | translate"
    class="confirm-value"></p>
</div>

<su-card [card]="cardConfirm" *ngIf="filledForm">
   <div card-content data-debug="form-confirm">
    <ng-container *ngFor="let groups of formFields">
      <ng-container *ngIf="groups.fieldGroup[0] as firstFieldGroup">
        <ng-container *ngIf="firstFieldGroup?.form.value as formValue">
          <div *ngIf="showChapter(firstFieldGroup.props.chapterTitle)">
            <div *ngIf="firstFieldGroup?.type == 'chapter'">
              <div class="confirm-label">
                <span *ngIf="firstFieldGroup.props.chapterTitle == ('ticket.create.label__notification' | translate)">
                  {{ 'ticket.create.confirm.label__ccemail' | translate }}:
                </span>
                <span *ngIf="firstFieldGroup.props.chapterTitle == ('ticket.create.headline__order_information' | translate)">
                  {{groups.fieldGroup[1].props.label}}:
                </span>
                <span *ngIf="firstFieldGroup.props.chapterTitle !== ('ticket.create.label__notification' | translate)
                            && firstFieldGroup.props.chapterTitle !== ('ticket.create.headline__order_information' | translate)
                            && firstFieldGroup.props.chapterTitle !== ('ticket.create.headline__attachment' | translate)
                            && firstFieldGroup.props.chapterTitle !== ('ticket.create.headline__further_details' | translate)
                ">
                  {{firstFieldGroup.props.chapterTitle}}:
                </span>
              </div>
              <div *ngIf="firstFieldGroup?.type == 'chapter' && firstFieldGroup?.props.visibleInConfirm">
                <su-headline skin="secondary" h="h2" *ngIf="firstFieldGroup.props.chapterTitle == ('ticket.create.headline__attachment' | translate)">
                  {{ 'ticket.create.confirm.headline__attachment' | translate }}
                </su-headline>
                <su-headline skin="secondary" h="h2" *ngIf="firstFieldGroup.props.chapterTitle == ('ticket.create.headline__further_details' | translate)">
                  {{ 'ticket.create.headline__further_details' | translate }}
                </su-headline>
              </div>
            </div>
            <div *ngIf="firstFieldGroup?.props.chapterTitle === ('ticket.create.headline__order_information' | translate)">
              <span class="confirm-value">
                {{formValue.shortTitle}}
              </span>
            </div>
            <div *ngIf="firstFieldGroup?.props.chapterTitle === ('ticket.create.headline__contact_orderer' | translate)">
              <span class="confirm-value">
                {{formValue.ordererContact.firstname}}
                {{formValue.ordererContact.lastname}},
                {{formValue.ordererContact.phonenumber}},
                {{formValue.ordererContact.email}},
                {{formValue.ordererContact.corporation}},
                {{formValue.ordererContact.operation}}
              </span>
            </div>
            <div *ngIf="firstFieldGroup?.props.chapterTitle === ('ticket.create.headline__closure_location' | translate)">
              <span class="confirm-value">
                {{formValue.searchLocation.searchResult.id}},
                {{formValue.searchLocation.searchResult.city}},
                {{formValue.searchLocation.searchResult.streetName}}
              </span>
            </div>
            <div *ngIf="firstFieldGroup?.props.chapterTitle === ('ticket.create.headline__shipping_address' | translate)">
              <span class="confirm-value">
                {{formValue.shippingAddress.contactInformation.firstname}}
                {{formValue.shippingAddress.contactInformation.lastname}},
                {{formValue.shippingAddress.contactInformation.email}},
                {{formValue.shippingAddress.address.street}},
                {{formValue.shippingAddress.address.zipcode}}
                {{formValue.shippingAddress.address.city}}
              </span>
            </div>
            <div *ngIf="firstFieldGroup?.props.chapterTitle == ('ticket.create.headline__attachment' | translate) 
                        && formValue.attachments.length > 0">
              <div *ngFor="let file of formValue.attachments">
                <div class="confirm-label">
                  <span>{{ 'ticket.create.confirm.label__attachmentType' | translate }}</span>:
                </div>
                <span class="confirm-value">{{ getAttachmentTypeLabel(file.attachmentType) }}</span>
                <br /><br />
                <div class="confirm-label">
                  <span>{{ 'ticket.create.confirm.label__attachment' | translate }}</span>:
                </div>
                <span class="confirm-value">{{ file.attachment?.name }}</span>
                <span *ngIf="file !== formValue.attachments[formValue.attachments.length - 1]">
                  <br /><br />
                </span>
              </div>
            </div>
            <div *ngIf="firstFieldGroup?.props.chapterTitle === ('ticket.create.headline__further_details' | translate)">
              <span class="confirm-value">
                <div class="confirm-label">{{'ticket.create.label__producer' | translate}}:</div>
                <span *ngIf="formValue.producer">{{formValue.producer}} </span>
                <span *ngIf="!formValue.producer">--</span>
                <br><br>
                <div class="confirm-label">{{'ticket.create.label__keyNumber' | translate}}:</div>
                <span *ngIf="formValue.keyNumber">{{formValue.keyNumber}} </span>
                <span *ngIf="!formValue.keyNumber">--</span>
                <br><br>
                <div class="confirm-label">{{'ticket.create.label__keyQuantity' | translate}}:</div>
                <span>{{formValue.keyQuantity}} </span>
                <br><br>
                <div class="confirm-label">{{'ticket.create.label__assetNumber' | translate}}:</div>
                <span *ngIf="formValue.assetNumber.assetNumber">{{formValue.assetNumber.assetNumber}}</span>
                <span *ngIf="!formValue.assetNumber.assetNumber">--</span>
              </span>
            </div>
            <div *ngIf="firstFieldGroup?.key === 'additionalKey'">
              <su-headline skin="secondary" h="h2">{{ 'ticket.create.order_access.additional_keys' | translate }}</su-headline>
              <span class="confirm-value">
                <span *ngIf="formValue.additionalKey.length == 0">--</span>
                <ng-container *ngFor="let addKey of formValue.additionalKey">
                  <div class="confirm-label">{{'ticket.create.label__producer' | translate}}:</div>
                  <span *ngIf="addKey.producer">{{addKey.producer}} </span>
                  <span *ngIf="!addKey.producer">--</span>
                  <br><br>
                  <div class="confirm-label">{{'ticket.create.label__keyNumber' | translate}}:</div>
                  <span *ngIf="addKey.keyNumber">{{addKey.keyNumber}} </span>
                  <span *ngIf="!addKey.keyNumber">--</span>
                  <br><br>
                  <div class="confirm-label">{{'ticket.create.label__keyQuantity' | translate}}:</div>
                  <span>{{addKey.keyQuantity}} </span>
                  <br><br>
                  <div class="confirm-label">{{'ticket.create.label__assetNumber' | translate}}:</div>
                  <span *ngIf="addKey.assetNumber.assetNumber">{{addKey.assetNumber.assetNumber}}</span>
                  <span *ngIf="!addKey.assetNumber.assetNumber">--</span>
                  <br>
                  <ng-container *ngIf="addKey !== formValue.additionalKey[formValue.additionalKey.length - 1]">
                    <br>
                    <span>------------------------------------------------------------</span>
                    <br>
                    <br>
                  </ng-container>
                </ng-container>
              </span>
            </div>
            <div *ngIf="firstFieldGroup?.key === 'additionalInformation'">
              <ng-container *ngFor="let item of firstFieldGroup.fieldGroup">
                <div class="confirm-label">
                  <span>{{item.props.label}}</span>:
                </div>
                <span *ngIf="item.key === 'totalKeys'">
                  <span class="confirm-value" *ngIf="totalKeys">
                    {{totalKeys}}
                  </span>
                  <span class="confirm-value" *ngIf="!totalKeys">--</span>
                </span>
                <span *ngIf="!(item.key === 'totalKeys')">
                  <span class="confirm-value" *ngIf="formValue.additionalInformation.additionalInformations">
                    {{formValue.additionalInformation.additionalInformations}}
                  </span>
                  <span class="confirm-value" *ngIf="!formValue.additionalInformation.additionalInformations">--</span>
                </span>
                <br><br *ngIf="item !== firstFieldGroup.fieldGroup[firstFieldGroup.fieldGroup.length - 1]">
              </ng-container>
            </div>
            <div *ngIf="firstFieldGroup?.props.chapterTitle === ('ticket.create.label__notification' | translate)">
              <span class="confirm-value" *ngIf="formValue.ccemail && formValue.ccemail !== ''">
                {{formValue.ccemail}}
              </span>
              <span class="confirm-value" *ngIf="!formValue.ccemail || formValue.ccemail === ''">--</span>
            </div>
            <br>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <form (ngSubmit)="submit()">
      <div class="confirm-buttons">
        <su-button
          [skin]="'primary'"
          [icon]="'arrow-left'"
          [label]="'ticket.create.confirm.button__back_to_edit' | translate"
          (onClick)="pressBackToEdit()">
        </su-button>
        <su-button
          id="buttonSendInConfirmForm"
          type="submit"
          [skin]="'accent'"
          [icon]="'paper-plane'"
          [label]="'ticket.create.confirm.button__send' | translate"
          [disabled]="isSubmitted">
        </su-button>
      </div>
    </form>
 
  </div>
</su-card>

<app-spinner-overlay [show]="loading"></app-spinner-overlay>