import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormlyFieldBaseService } from '@core/services/formly-service/formly-field.service';
import { ArchObjHierarchyViewModel, ArchObjToAddressViewModel, LocationClient } from '@core/api';
import { FormlyPreviewAutocompleteProps } from './formly-service.types';

@Injectable({
  providedIn: 'root',
})
export class LocationFormlyFieldService extends FormlyFieldBaseService {
  override translatePath = 'location.autocomplete';
  
  public defaultList$ = new BehaviorSubject<any[]>([]);

  constructor(
    protected override translateService: TranslateService,
    private locationClient: LocationClient,
  ) {
    super(translateService);
  }

  autocompleteSearch(
    searchBuildings: boolean,
    defaultValue: ArchObjHierarchyViewModel,
    showDefaultList: boolean,
    onSelect: (id: string, onInitSearch: boolean) => void,
    columnDefinition?: (
      | {
          fieldName: string;
          labelTranslationKey: string;
          classes: string;
          formatter?: undefined;
        }
      | {
          fieldName: string;
          labelTranslationKey: string;
          classes: string;
          formatter: (x: any) => any;
        }
    )[],
    onDelete?: () => void,
  ): FormlyFieldConfig[] {
    const translatePath = 'location.autocomplete';
    
    if(showDefaultList){
      this.getDefaultList();
    } else {
      this.defaultList$.next([]);
    }

    let columnDefinitions = [
      {
        fieldName: 'id',
        labelTranslationKey: translatePath + '.WE-No',
        classes: 'text-overflow',
      },
      {
        fieldName: 'postalCode',
        labelTranslationKey: translatePath + '.zip',
        classes: '',
        formatter: (x: ArchObjToAddressViewModel) => x?.address?.postalCode ?? '',
      },
      {
        fieldName: 'city',
        labelTranslationKey: translatePath + '.city',
        classes: 'text-overflow',
        formatter: (x: ArchObjToAddressViewModel) => x?.address?.city ?? '',
      },
      {
        fieldName: 'streetName',
        labelTranslationKey: translatePath + '.street',
        classes: 'text-overflow',
        formatter: (x: ArchObjToAddressViewModel) =>
        x?.address?.streetName ??
        '' + (x?.address?.houseNumber ? ' ' + x?.address?.houseNumber : ''),
      },
      {
        fieldName: 'favorite',
        labelTranslationKey: '',
        classes: 'icon',
        type: 'icon',
        icon: 'star',
        formatter: (x: ArchObjHierarchyViewModel) => {
          if(!x?.isFavorite){
            return '';
          }
          return 'star';
        },
        hideInPreview: true,
      },
    ];

    if (columnDefinition) {
      columnDefinitions = columnDefinition;
    }

    if (searchBuildings) {
      columnDefinitions.splice(1, 0, {
        fieldName: 'siteAdditionalNumber',
        labelTranslationKey: translatePath + '.WE-Additional-No',
        classes: '',
      });
    }

    const props: FormlyPreviewAutocompleteProps = {
      required: true,
      visibleInConfirm: true,
      bindLabel: 'id',
      bindValue: 'id',
      columnDefinitions,
      defaultValue: defaultValue,
      defaultList: this.defaultList$,
      onSearch: (searchText: string): Observable<any> => {
        return this.locationClient
          .searchArchobjForAutoCompleteInexact(searchText)
          .pipe(map(x => x.entites));
      },
      onSelect,
      onDelete,
      label: this.translateService.instant(
        'location.autocomplete.label__placeholder'
      )
    };

    return [
      {
        type: 'preview-autocomplete',
        key: 'searchResult',
        props: { ...props },
        validation: {
          messages: {
            required: this.translateService.instant(
              'formErrors.location.empty'
            ),
          },
        },
      },
    ];
  }

  public getDefaultList(){
      this.locationClient?.getFavorites().subscribe({
        next: res => {
          this.defaultList$.next(res);
        }
      })
  }
}
