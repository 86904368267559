import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fact-item',
  templateUrl: './fact-item.component.html',
  styleUrls: ['./fact-item.component.scss'],
})
export class FactItemComponent {
  @Input() iconUrl = 'assets/icons/dummy-icon.svg';
  @Input() factAmount = '0';
  @Input() factType = '';
  @Input() subtitle = 'subtitle';

  getNumberFromString(str: string) {
    if (!isNaN(Number(str))) {
      return Number(str);
    } else {
      console.log('not a number!');
      return 0;
    }
  }
}
