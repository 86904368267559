<su-headline h="h1" skin="primary">{{ headline }}</su-headline>
<su-headline
  *ngIf="addressString"
  h="h2"
  skin="tertiary"
  class="selectionHeadline">
  {{ addressString }}
  <button
    class="icon-favorite"
    *ngIf="selectedLocation.isFavorite"
    mat-icon-button
    [matTooltip]="'location.myLocation.favorites.delete' | translate"
    [disabled]="disableFavoriteIcon"
    (click)="toggleFavorite()">
    <mat-icon>star</mat-icon>
  </button>
  <button
    class="icon-favorite"
    *ngIf="!selectedLocation.isFavorite"
    mat-icon-button
    [matTooltip]="'location.myLocation.favorites.create' | translate"
    [disabled]="disableFavoriteIcon"
    (click)="toggleFavorite()">
    <mat-icon>star_border</mat-icon>
  </button>
</su-headline>

<su-button
  [label]="'location.myLocation.helpdesk_message' | translate"
  skin="primary"
  (onClick)="createBuildingFault()"
  [disabled]="isBuildingFaultButtonDisabled"></su-button>

<su-slot-tabs [tabs]="tabs" [activeTab]="activeTab"></su-slot-tabs>

<su-slot-tab-content *ngIf="activeTab === tabs[0].id">
  <su-card [card]="cardSearch">
    <div card-content>
      <formly-form
        [form]="form"
        [fields]="formFields"
        [model]="formModel"></formly-form>
    </div>
  </su-card>
</su-slot-tab-content>
<su-slot-tab-content *ngIf="activeTab === tabs[1].id">
  <div class="table-description">
    {{ 'location.myLocation.favorites.info' | translate }}
  </div>
  <mtx-grid
    id="favoriteLocations"
    [columns]="columnsFavorites"
    [data]="locationFavorites"
    [loading]="isLoading"
    [columnResizable]="config.grid.columnResizable"
    [rowStriped]="true"
    [showPaginator]="false"
    (rowClick)="onSelect($event.rowData.id)">
  </mtx-grid>
</su-slot-tab-content>
<su-slot-tab-content *ngIf="activeTab === tabs[2].id">
  <div style="display: flex">
    <su-card [card]="cardMap">
      <div card-content>
        <div [ngClass]="{ 'obj-infos-container': !isMobile }">
          <div style="display: flex; flex-direction: column">
            <div class="row" [ngClass]="{ rowMobile: isMobile }">
              <label class="col-5 info-label">{{
                'location.myLocation.location_information.label__we_no'
                  | translate
              }}</label>
              <span class="col">{{
                selectedLocation.dtArchObjAddress[0].archObjId
              }}</span>
            </div>
            <div class="row" [ngClass]="{ rowMobile: isMobile }">
              <label class="col-5 info-label">{{
                'location.myLocation.location_information.label__street'
                  | translate
              }}</label>
              <span class="col"
                >{{ selectedLocation.dtArchObjAddress[0].address.streetName
                }}{{
                  selectedLocation.dtArchObjAddress[0].address.houseNumber
                }}</span
              >
            </div>
            <div class="row" [ngClass]="{ rowMobile: isMobile }">
              <label class="col-5 info-label">{{
                'location.myLocation.location_information.label__zip'
                  | translate
              }}</label>
              <span class="col">{{
                selectedLocation.dtArchObjAddress[0].address.postalCode
              }}</span>
            </div>
            <div class="row" [ngClass]="{ rowMobile: isMobile }">
              <label class="col-5 info-label">{{
                'location.myLocation.location_information.label__city'
                  | translate
              }}</label>
              <span class="col">{{
                selectedLocation.dtArchObjAddress[0].address.city
              }}</span>
            </div>
            <div *ngIf="isMobile" class="mapMobile">
              <app-location-map
                [addressString]="addressString"></app-location-map>
            </div>
            <div *ngIf="isDataManager" style="margin-top: auto">
              <su-button
                [label]="editLocationInfoTitle"
                skin="secondary"
                (onClick)="editLocationInformation()">
              </su-button>
            </div>
          </div>
          <div *ngIf="!isMobile" class="map">
            <app-location-map
              [addressString]="addressString"></app-location-map>
          </div>
        </div>
      </div>
    </su-card>

    <div class="su-card-end-screen">
      <su-card [card]="cardHints" *ngIf="locationInfosHints.length > 0">
        <div card-content>
          <div class="details-container">
            <ng-container *ngFor="let item of locationInfosHints">
              <ng-container
                *ngTemplateOutlet="
                  locationInfoTemplate;
                  context: { $implicit: item }
                "></ng-container>
            </ng-container>
          </div>
        </div>
      </su-card>

      <su-card [card]="cardServices" *ngIf="locationInfosServices.length > 0">
        <div card-content>
          <div class="details-container">
            <ng-container *ngFor="let item of locationInfosServices">
              <ng-container
                *ngTemplateOutlet="
                  locationInfoTemplate;
                  context: { $implicit: item }
                "></ng-container>
            </ng-container>
          </div>
        </div>
      </su-card>

      <su-card
        [card]="cardAdditionalServices"
        *ngIf="locationInfosAdditionalServices.length > 0">
        <div card-content>
          <div class="details-container">
            <ng-container *ngFor="let item of locationInfosAdditionalServices">
              <ng-container
                *ngTemplateOutlet="
                  locationInfoTemplate;
                  context: { $implicit: item }
                "></ng-container>
            </ng-container>
          </div>
        </div>
      </su-card>

      <su-card
        [card]="cardUnpublishedInfos"
        *ngIf="isDataManager && locationInfosUnpublished.length > 0">
        <div card-content>
          <div class="details-container">
            <ng-container *ngFor="let item of locationInfosUnpublished">
              <ng-container
                *ngTemplateOutlet="
                  locationInfoTemplate;
                  context: { $implicit: item }
                "></ng-container>
            </ng-container>
          </div>
        </div>
      </su-card>
    </div>
  </div>

  <app-spinner-overlay [show]="isLoading" [label]="''"></app-spinner-overlay>
</su-slot-tab-content>

<ng-template #locationInfoTemplate let-item>
  <div class="detail-item">
    <div class="detail-icon">
      <su-icon
        [matTooltip]="item.category?.name"
        [icon]="item.category?.icon" />
    </div>
    <div class="detail-text">
      <div [innerHTML]="item.text | safeHtml"></div>
      <a
        *ngIf="item.link"
        [href]="
          item.category?.id === LocationInfoCategory.Contact ||
          item.category?.id === LocationInfoCategory.Reception
            ? 'mailto:' + item.link
            : item.link
        "
        target="_blank"
        [matTooltip]="item.link"
        >{{
          item.linkName ??
            'location.myLocation.location_information.further_information'
            | translate
        }}</a
      >
    </div>
    <div class="date-container">
      <div *ngIf="isDataManager" class="buttons-container">
        <su-button
          icon="edit"
          skin="secondary"
          (onClick)="openEditDialog(item, true)"
          class="button-content-center">
        </su-button>
        <su-button
          icon="trash"
          skin="secondary"
          (onClick)="deleteLocationInformation(item.id)"
          class="button-content-center">
        </su-button>
      </div>
      <div>
        {{
          'location.myLocation.location_information.lastUpdatedOn' | translate
        }}
      </div>
      <div class="date">
        {{ item.changeDate | date: 'dd.MM.yyyy' }}
      </div>
    </div>
  </div>
</ng-template>

<su-slot-tab-content *ngIf="activeTab === tabs[3].id">
  <div class="table-description">
    {{ 'location.myLocation.malfunction_messages.info' | translate }}
  </div>
  <mtx-grid
    [columns]="columns"
    [data]="locationIncidents.entites"
    [length]="locationIncidents.totalCount"
    [loading]="isLoading"
    [columnSortable]="true"
    [columnResizable]="config.grid.columnResizable"
    [columnPinnable]="false"
    [pageOnFront]="false"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    (page)="getNextPage($event)"
    [pageSize]="config.grid.paging.pageSize"
    [pageIndex]="config.grid.paging.pageIndex"
    [showPaginator]="config.grid.paging.showPaginator"
    [rowStriped]="true">
  </mtx-grid>
</su-slot-tab-content>

<su-slot-tab-content *ngIf="activeTab === tabs[4]?.id">
  <app-location-notification
    [location]="selectedLocation"></app-location-notification>
</su-slot-tab-content>

<ng-template #favoriteIconTemplate let-row>
  <mat-icon
    (click)="deleteFavorite($event, row)"
    [matTooltip]="'location.myLocation.favorites.delete' | translate">
    star
  </mat-icon>
</ng-template>
